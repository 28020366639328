<template>
  <div class="noticebig" :style="{ background: NoticeConfig.bgColor }">
    <div class="noticebox" :style="{ background: NoticeConfig.NbgColor, height: NoticeConfig.boxHeight + 'px', borderRadius: NoticeConfig.radiusNum + 'px' }">
      <i v-if="NoticeConfig.imgStyle == 0" :class="['iconfont', NoticeConfig.icon]" :style="{ color: NoticeConfig.color }"></i>
      <div v-if="NoticeConfig.imgStyle == 1" class="noticeImg">
        <img v-if="NoticeConfig.imgUrl != ''" :src="NoticeConfig.imgUrl" alt="" />
      </div>
      <div class="noticeTitle" :style="{ color: NoticeConfig.color }">
        {{ NoticeConfig.isGoldPrice === 1 ? NoticeConfig.list[0].title : '实时金价：***元/克' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    NoticeConfig: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.noticebig {
  width: 100%;
  padding: 3px 0;
}
.noticebox {
  margin: 0 13px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  i {
    font-size: 20px;
  }
  .noticeImg {
    width: 24px;
    height: 24px;
    // border-radius: 50%;
    overflow: hidden;
  }
  .noticeTitle {
    width: calc(100% - 64px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 16px;
  }
}
</style>
