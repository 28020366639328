<template>
  <div class="style_0">
    <div class="set_s_card">
      <div class="card_title">文字设置</div>
      <div class="set_item">
        <div>字段1</div>
        <el-input v-model="controlDefault.txt_1"></el-input>
      </div>
      <div class="set_item">
        <div>字段2</div>
        <el-input v-model="controlDefault.txt_2"></el-input>
      </div>
      <div class="set_item">
        <div>字段3</div>
        <el-input :maxlength="4" v-model="controlDefault.txt_3"></el-input>
      </div>
      <div class="set_item">
        <div>价格1</div>
        <el-input v-model="controlDefault.price_1"></el-input>
      </div>
      <div class="set_item">
        <div>价格2</div>
        <el-input v-model="controlDefault.price_2"></el-input>
      </div>
    </div>
    <div class="set_s_card l">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <div class="content">
            <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
            <div class="blue-l" @click="controlDefault.bgColor = '#fff'">重置</div>
          </div>
        </div>
      </div>
      <div class="set_item">
        <div>标题颜色</div>
        <div class="content">
          <div class="content">
            <el-color-picker v-model="controlDefault.titleColor"></el-color-picker>
            <div class="blue-l" @click="controlDefault.titleColor = '#333'">重置</div>
          </div>
        </div>
      </div>
      <div class="set_item">
        <div>副标题颜色</div>
        <div class="content">
          <div class="content">
            <el-color-picker v-model="controlDefault.subTitleColor"></el-color-picker>
            <div class="blue-l" @click="controlDefault.subTitleColor = '#999'">重置</div>
          </div>
        </div>
      </div>
    </div>
    <div class="set_s_card">
      <div class="card_title">图片设置</div>
      <div class="set_item flex_start">
        <div>选择图片</div>
        <div class="content">
          <div class="operationBox">
            <div class="imgBox">
              <img v-if="controlDefault.img_1 != ''" :src="controlDefault.img_1" alt="" />
              <div class="upload" @click="chooseImgFlag = true">选择图片</div>
            </div>
            <div class="del-l" @click="controlDefault.img_1 = ''">清除</div>
          </div>
        </div>
      </div>
    </div>
    <div class="set_s_card">
      <div class="card_title">跳转设置</div>
      <div class="set_item">
        <div>跳转路径</div>
        <div class="content">
          <el-input disabled v-model="controlDefault.toPage" placeholder="请选择跳转路径"></el-input>
          <div class="blue-l" @click="choosePage">选择</div>
        </div>
      </div>
    </div>
    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
    <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag"
      @getSource="getImgRes"></UploadSource>
  </div>
</template>

<script>
import UploadSource from '@/components/uploadSource';
import ToPage from '@/views/addPages/toPage/index.vue';
export default {
  components: {
    UploadSource,
    ToPage
  },
  props: {
    controlDefault: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      chooseImgFlag: false,
      toPageFlag: false,
    }
  },
  methods: {
    changeStatus (val) {
      this.chooseImgFlag = val;
    },
    getImgRes (data) {
      this.controlDefault.img_1 = data[0].path
    },
    choosePage () {
      this.toPageFlag = true;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    // 拿到跳转路径
    getPageRes (item) {
      this.controlDefault.toPage = item.wxroute;
      this.toPageFlag = false;
    },
  }

}
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);

.operationBox {
  width: 100%;
  display: flex;
  align-items: flex-end;

  .imgBox {
    width: 88px;
    height: 88px;
    background-color: #fff;
    border-radius: 5px;
    margin-right: 16px;
    .flex();

    img {
      width: 80px !important;
      height: 80px !important;
    }

    position: relative;

    .upload {
      cursor: pointer;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 25px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 0px 0px 5px 5px;
      .title(14px, #fff, 400);
      .flex();
    }
  }
}
</style>