<template>
  <div class="indexBox11">
    <HeaderBg v-if="componentStyle.style == 0" :index="index" :HeaderBg="componentStyle.config" :action="action"></HeaderBg>
    <Banner v-if="componentStyle.style == 1" :index="index" :bannerArr="componentStyle.config" :action="action"></Banner>
    <Search v-if="componentStyle.style == 2" :searchBox="componentStyle.config"></Search>
    <BtnGroup v-if="componentStyle.style == 3" :btnGroup="componentStyle.config"></BtnGroup>
    <PictureWindow v-if="componentStyle.style == 4" :imgWindow="componentStyle.config"></PictureWindow>
    <RichText v-if="componentStyle.style == 5" :richTxtContant="componentStyle.config"></RichText>
    <Notice v-if="componentStyle.style == 6" :NoticeConfig="componentStyle.config"></Notice>
    <Video v-if="componentStyle.style == 7" :VideoConfig="componentStyle.config"></Video>
    <GoodsGroup v-if="componentStyle.style == 8" :GoodsGroup="componentStyle.config"></GoodsGroup>
    <ShopGroup v-if="componentStyle.style == 9" :ShopGroup="componentStyle.config"></ShopGroup>
    <TxtGroup v-if="componentStyle.style == 10" :TxtGroup="componentStyle.config"></TxtGroup>
    <TitleBar v-if="componentStyle.style == 11" :TitleBar="componentStyle.config"></TitleBar>
    <Guide v-if="componentStyle.style == 12" :Guide="componentStyle.config"></Guide>
    <AllGoods v-if="componentStyle.style == 13" :basicSet="basicSet" :AllGoods="componentStyle.config"></AllGoods>
    <Coupon v-if="componentStyle.style == 14" :Coupon="componentStyle.config"></Coupon>
    <LiveRoom v-if="componentStyle.style == 15" :liveRoom="componentStyle.config"></LiveRoom>
    <Collage v-if="componentStyle.style == 16" :collageConfig="componentStyle.config"></Collage>
    <VideoNumber v-if="componentStyle.style == 17" :videoNumberConfig="componentStyle.config"></VideoNumber>
    <CustomerService v-if="componentStyle.style == 18" :customerServiceConfig="componentStyle.config"></CustomerService>
    <SetMeal v-if="componentStyle.style == 19" :setMealConfig="componentStyle.config"></SetMeal>
    <CardItem v-if="componentStyle.style == 20" :cardItemConfig="componentStyle.config"></CardItem>
    <ServiceGroup v-if="componentStyle.style == 21" :serviceGroupConfig="componentStyle.config"></ServiceGroup>
    <AllService v-if="componentStyle.style == 22" :basicSet="basicSet" :allServiceConfig="componentStyle.config"></AllService>
    <ShopDetail v-if="componentStyle.style == 23" :shopDetailConfig="componentStyle.config"></ShopDetail>
    <Pay v-if="componentStyle.style == 24" :config="componentStyle.config"></Pay>
    <ScrollBar v-if="componentStyle.style == 25" :config="componentStyle.config"></ScrollBar>
    <Style_s_0 v-if="componentStyle.style == 's_0'" :config="componentStyle.config" :choose_s_color="choose_s_color"></Style_s_0>
    <Style_s_1 v-if="componentStyle.style == 's_1'" :config="componentStyle.config" :choose_s_color="choose_s_color"></Style_s_1>
    <Style_s_2 v-if="componentStyle.style == 's_2'" :config="componentStyle.config" :choose_s_color="choose_s_color"></Style_s_2>
    <Style_s_3 v-if="componentStyle.style == 's_3'" :config="componentStyle.config" :choose_s_color="choose_s_color"></Style_s_3>
    <Style_s_4 v-if="componentStyle.style == 's_4'" :config="componentStyle.config" :choose_s_color="choose_s_color"></Style_s_4>
    <Style_s_5 v-if="componentStyle.style == 's_5'" :config="componentStyle.config" :choose_s_color="choose_s_color"></Style_s_5>
    <Style_s_6 v-if="componentStyle.style == 's_6'" :config="componentStyle.config" :choose_s_color="choose_s_color"></Style_s_6>
    <Style_s_7 v-if="componentStyle.style == 's_7'" :config="componentStyle.config" :choose_s_color="choose_s_color"></Style_s_7>
  </div>
</template>

<script>
import Banner from './BasicComponents/banner/banner';
import Search from './BasicComponents/search/search';
import BtnGroup from './BasicComponents/btnGroup/btnGroup';
import PictureWindow from './BasicComponents/pictureWindow/pictureWindow';
import RichText from './BasicComponents/richText/richText';
import Notice from './BasicComponents/notice/notice';
import Video from './BasicComponents/video/video';
import GoodsGroup from './CommodityComponents/goodsGroup/goodsGroup';
import ShopGroup from './BasicComponents/shopGroup/shopGroup';
import TxtGroup from './BasicComponents/txtGroup/txtGroup';
import TitleBar from './BasicComponents/titleBar/titleBar';
import Guide from './BasicComponents/guide/guide';
import HeaderBg from './BasicComponents/headerBg/headerBg';
import AllGoods from './CommodityComponents/allGoods/allGoods';
import Coupon from './MarketingComponent/Coupon/Coupon.vue';
import LiveRoom from './MarketingComponent/liveRoom/liveRoom.vue';
import Collage from './MarketingComponent/collage/collage.vue';
import VideoNumber from './MarketingComponent/videoNumber/videoNumber.vue';
import SetMeal from './MarketingComponent/setMeal/setMeal.vue';
import CustomerService from './BasicComponents/customerService/customerService.vue';
import CardItem from './ServiceComponent/cardItem/cardItem.vue';
import ServiceGroup from './ServiceComponent/serviceGroup/serviceGroup.vue';
import AllService from './ServiceComponent/allService/allService.vue';
import ShopDetail from './ServiceComponent/shopDetail/shopDetail.vue';
import Style_s_0 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_0/style_s_0.vue';
import Style_s_1 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_1/style_s_1.vue';
import Style_s_2 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_2/style_s_2.vue';
import Style_s_3 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_3/style_s_3.vue';
import Style_s_4 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_4/style_s_4.vue';
import Style_s_5 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_5/style_s_5.vue';
import Style_s_6 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_6/style_s_6.vue';
import Style_s_7 from '@/views/addPages/uicomponents/MaterialLibrary/style_s_7/style_s_7.vue';

import Pay from './BasicComponents/pay/pay';
import ScrollBar from './BasicComponents/scrollBar/scrollBar.vue';
export default {
  props: {
    action: {
      type: Object,
      default: () => {},
    },
    componentStyle: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: () => -1,
    },
    basicSet: {
      type: Object,
      default: () => {},
    },
    choose_s_color: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    HeaderBg,
    Banner,
    Search,
    BtnGroup,
    PictureWindow,
    RichText,
    Notice,
    Video,
    GoodsGroup,
    ShopGroup,
    TxtGroup,
    TitleBar,
    Guide,
    AllGoods,
    Coupon,
    LiveRoom,
    Collage,
    VideoNumber,
    SetMeal,
    CustomerService,
    CardItem,
    ServiceGroup,
    AllService,
    ShopDetail,
    Style_s_0,
    Style_s_1,
    Style_s_2,
    Style_s_3,
    Style_s_4,
    Style_s_5,
    Style_s_6,
    Style_s_7,
    Pay,
    ScrollBar,
  },
};
</script>

<style lang="less" scoped></style>
