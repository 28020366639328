/**
 * @param 组件类库
 * 装修组件
 */

//  公共属性
class ShareAttr {
  // 暂未抽取公共属性
  constructor() {}
}
// 头部组件
class HeaderPart extends ShareAttr {
  constructor() {
    super();
  }
  layout = 0;
  bannerConfig = [
    {
      status: '1',
      imgURL: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png',
      toPage: '跳转页面',
    },
  ];
  searchConfig = {
    layout: 0,
    bgColor: null,
    txt: '请输入搜索商品',
  };
  bgColorOne = null;
  bgColorTwo = null;
  bgImg = '';
  c_name = '头部背景';
}

// banner图组件
class Banner extends ShareAttr {
  constructor() {
    super();
  }
  bgColor = null;
  list = [
    {
      status: '1',
      imgURL: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bannerDefault.png',
      toPage: '跳转页面',
    },
  ];
  chooseMargin = 1;
  c_name = '轮播图';
}

// 搜索组件
class SearchBox extends ShareAttr {
  constructor() {
    super();
  }
  color = null;
  isPositon = 0;
  isFixed = 0;
  tipsTxt = '请输入搜索商品';
  fontColor = '#333';
  searchBg = '#fff';
  searchBorder = '#ccc';
  c_name = '搜索框';
}

// 按钮组
class BtnGroup extends ShareAttr {
  constructor() {
    super();
  }
  layout = 3;
  list = [
    {
      name: '按钮文字',
      imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
      toPage: '跳转页面',
    },
  ];
  bgColor = null;
  c_name = '按钮组';
}

// 图片橱窗组件
class PictureWindow extends ShareAttr {
  constructor() {
    super();
  }
  list = [
    {
      status: '1',
      imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bigzheng.png',
      toPage: '跳转页面',
    },
    {
      status: '1',
      imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bigzheng.png',
      toPage: '跳转页面',
    },
    {
      status: '1',
      imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bigzheng.png',
      toPage: '跳转页面',
    },
    {
      status: '1',
      imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bigzheng.png',
      toPage: '跳转页面',
    },
  ];
  bgColor = null;
  imgCount = 4;
  layout = 0;
  c_name = '图片橱窗';
}

// 富文本组件
class RichTxt extends ShareAttr {
  constructor() {
    super();
  }
  bgColor = null;
  richTxt = '编辑图文信息';
  c_name = '富文本';
}

// 公告栏组件
class Notice extends ShareAttr {
  constructor() {
    super();
  }
  bgColor = null;
  NbgColor = '#f2e3e3';
  color = '#ff4444';
  // 0-icon 1-img
  imgStyle = 0;
  // 是否显示金价
  isGoldPrice = 1;
  // 圆角弧度
  radiusNum = 20;
  // 盒子高度
  boxHeight = 40;
  icon = 'icon-laba1';
  imgUrl = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/notice.png';
  list = [{ toPage: '跳转页面', title: '这是第一条自定义公告' }];
  c_name = '公告栏';
}

// 视频组件
class VideoComponent extends ShareAttr {
  constructor() {
    super();
  }
  bgColor = null;
  status = '1';
  imgUrl = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bigzheng.png';
  videoUrl = '';
  videoTitle = '视频标题';
  c_name = '视频';
}

// 商品组组件
class GoodsGroup extends ShareAttr {
  constructor() {
    super();
  }
  // 布局方式 0-单列 1-双列 2-三列 3-列表显示 4-单行滚动 5-品质优选
  layout = 1;
  bgColor = null;
  showPrice = 0;
  priceColor = '#333';
  // 商品来源 0-手动添加 1-分类
  goodsSource = 0;
  //加入购物车样式 0-加号 1-购物车图标 3-不显示
  addShopCarStyle = 0;
  ShopCarColor = '#333';
  preference = {
    borderRadius: 14,
    post_banner: 'https://eyunpay-club.oss-cn-guangzhou.aliyuncs.com/clubMini/muisc/chang_default.jpg',
    goods_bg: 'https://eyunpay-club.oss-cn-guangzhou.aliyuncs.com/clubMini/muisc/goods_bg.png',
    banner_router: '',
  };
  // 商品列表
  list = [
    {
      status: '1',
      goods_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/620zheng.png',
      goods_name: '商品名称',
      goods_price: '0.01',
    },
  ];
  // 商品分类
  goodsStyle_id = -1;
  goodsStyle_name = '分类名称';
  goodsNum = 0;
  totalList = [];
  c_name = '商品组';
}

// 商户组组件
class ShopGroup extends ShareAttr {
  constructor() {
    super();
  }
  bgColor = null;
  // 展示方式
  layout = 1;
  shopSource = 0;
  shopClassName = '';
  list = [
    {
      store_logo: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
      store_name: '商户名称',
      store_intro: '商户简介',
      goodsList: [
        {
          goods_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
        },
        {
          goods_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
        },
      ],
    },
    {
      store_logo: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
      store_name: '商户名称',
      store_intro: '商户简介',
      goodsList: [
        {
          goods_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
        },
        {
          goods_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
        },
      ],
    },
    {
      store_logo: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
      store_name: '商户名称',
      store_intro: '商户简介',
      goodsList: [
        {
          goods_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
        },
        {
          goods_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
        },
      ],
    },
  ];
  c_name = '商户组';
}

// 文章组组件
class TxtGroup extends ShareAttr {
  constructor() {
    super();
  }
  bgColor = null;
  layout = 0;
  txtSource = 0;
  classify_id = -1;
  classify_name = '文章分类';
  goodsNum = 0;
  totalList = [];
  list = [
    {
      picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png ',
      title: '文章标题',
      subtitle: '文章简介',
    },
  ];
  c_name = '文章组';
}

// 标题栏组件
class TitleBar extends ShareAttr {
  constructor() {
    super();
  }
  layout = 0;
  bgColor = null;
  isSubTitle = 0;
  SubTitle = '副标题';
  titleImg = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/xiaozheng.png';
  contant = '主标题';
  toPage = '';
  mainFontSize = 18;
  mainColor = '#333';
  secondFontSize = 14;
  secondColor = '#666';
  c_name = '标题栏';
}

// 辅助线
class Guide extends ShareAttr {
  constructor() {
    super();
  }
  color = '#ccc';
  height = 20;
  c_name = '辅助线';
}

// 全部商品
class AllGoods extends ShareAttr {
  constructor() {
    super();
  }
  bgColor = null;
  showPrice = 0;
  priceColor = '#333';
  carStyle = 0;
  carColor = '#333';
  showAll = 0;
  list = [
    {
      class_name: '选择分类',
      class_id: -2,
    },
  ];
  c_name = '全部商品';
}

// 优惠券
class Coupon extends ShareAttr {
  constructor() {
    super();
  }
  rowCount = 1;
  layout = 0;
  bgColor = null;
  componentColor = '#ff547b';
  list = [
    {
      consume_full: '条件',
      type: 1,
      subtract: '金额',
      discount: '折扣',
      name: '优惠券名称',
      rule: '优惠券使用规则',
      id: null,
    },
  ];
  c_name = '优惠券';
}

// 直播组件
class LiveRoom extends ShareAttr {
  constructor() {
    super();
  }
  layout = 1;
  bgColor = null;
  fontColor = '#333';
  btnColor = '#FF4F58';
  liveRoomInfo = { title: null, live_status: 107, goods: [] };
  c_name = '直播间';
}

// 拼团组件
class Collage extends ShareAttr {
  constructor() {
    super();
  }
  layout = 0;
  bgColor = null;
  goodsColorSystem = '#333333';
  collageColorSystem = '#FF5353';
  list = [
    {
      goods_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/620zheng.png',
      goods_name: '商品名称',
      goods_price: '0.01',
    },
    {
      goods_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/620zheng.png',
      goods_name: '商品名称',
      goods_price: '0.01',
    },
  ];
  c_name = '拼团';
}

// 视频号组件
class VideoNumber extends ShareAttr {
  constructor() {
    super();
  }
  videoNumberIconStyle = 0;
  defaultImgUrl = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/videoNumberLogo.png';
  uploadImgUrl = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/videoNumberLogo.png';
  videoNumberId = '';
  showTime = 0;
  c_name = '视频号';
}
// 客服组件
class CustomerService extends ShareAttr {
  constructor() {
    super();
  }
  logoUploadStyle = 0;
  defaultImgUrl = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/diyComponentsImg/customerServicePhone.png';
  uploadImgUrl = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/diyComponentsImg/customerServicePhone.png';
  setStyle = 0;
  tel = '';
  c_name = '客服设置';
}
// 套餐组件
class PackageComponents extends ShareAttr {
  constructor() {
    super();
  }
  colorSystem = '#b0917f';
  tag_txt = '组合套餐';
  add_txt = '立即抢购';
  list = [{ cover_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png', package_name: '套餐名称套餐名称套餐名称套餐名称套餐名称', type: 1, package_price: '', wxroute: '' }];
  c_name = '套餐设置';
}
// 卡项组件
class CardItem extends ShareAttr {
  constructor() {
    super();
  }
  layout = 1;
  buyLayout = 1;
  colorSystem = '#FF4B51';
  add_txt = '购卡';
  sub_title = '副标题副标题副标题副标题副标题副标题副标题';
  dataObj = {
    card_item_name: '按摩卡',
    card_item_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
    card_item_price: '80.00',
    create_time: 1660900625,
    id: 11,
    indate: 0,
    sort: 1,
    status: 1,
    type: 1,
    update_time: 1660900625,
  };
  c_name = '卡项设置';
}

// 服务组件
class ServiceGroup extends ShareAttr {
  constructor() {
    super();
  }
  // 布局方式 0-单列 1-双列 2-三列 3-列表显示 4-单行滚动
  layout = 0;
  bgColor = null;
  showPrice = 0;
  priceColor = '#333';
  // 商品来源 0-手动添加 1-分类
  serviceSource = 0;
  //加入购物车样式 0-加号 1-购物车图标 3-不显示
  addShopCarStyle = 0;
  ShopCarColor = '#333';
  // 商品列表
  list = [
    {
      status: '1',
      cover_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/620zheng.png',
      service_name: '服务名称',
      service_price: '0.01',
    },
  ];
  // 商品分类
  serviceStyle_id = -1;
  serviceStyle_name = '分类名称';
  serviceNum = 0;
  totalList = [];
  c_name = '服务组件';
}

// 全部服务
class AllService extends ShareAttr {
  constructor() {
    super();
  }
  bgColor = null;
  showPrice = 0;
  priceColor = '#333';
  carStyle = 0;
  carColor = '#333';
  showAll = 0;
  list = [
    {
      class_name: '选择分类',
      class_id: -2,
    },
  ];
  c_name = '全部服务';
}

//商户详情
class ShopDetail extends ShareAttr {
  constructor() {
    super();
  }
  layout = 0;
  bgColor = '#fff';
  wxLayout = 0;
  qrcode = '';
  navigationLayout = 0;
  lonLat = { lon: '116.397451', lat: '39.909187' };
  shopName = '店铺名称店铺名称';
  desc = '店铺详情';
  businessHours = '周一至周日 10:30-21:30';
  address = 'xxxxxxxxxx';
  tel = '888-8888-8888';
  wx = '88888888';
  list = ['https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png'];
  c_name = '商户详情';
}

//到店付款
class Pay extends ShareAttr {
  constructor() {
    super();
  }
  bgColor = null;
  imgURL = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/diyComponentsImg/pay.png';
  chooseMargin = 1;
  c_name = '到店付款';
}
// 滚动条 滚动个消费信息
class scrollBar extends ShareAttr {
  constructor() {
    super();
  }
  bgColorOne = '#FFECEC';
  bgColorTwo = '#FFFFFF';
  fillet = 10;
  c_name = '滚动条';
}
// 传入参数为 点击元素在组件列表中的索引
export const GenerateComponent = index => {
  let res = null;
  switch (index) {
    case 0:
      res = new HeaderPart();
      break;
    case 1:
      res = new Banner();
      break;
    case 2:
      res = new SearchBox();
      break;
    case 3:
      res = new BtnGroup();
      break;
    case 4:
      res = new PictureWindow();
      break;
    case 5:
      res = new RichTxt();
      break;
    case 6:
      res = new Notice();
      break;
    case 7:
      res = new VideoComponent();
      break;
    case 8:
      res = new GoodsGroup();
      break;
    case 9:
      res = new ShopGroup();
      break;
    case 10:
      res = new TxtGroup();
      break;
    case 11:
      res = new TitleBar();
      break;
    case 12:
      res = new Guide();
      break;
    case 13:
      res = new AllGoods();
      break;
    case 14:
      res = new Coupon();
      break;
    case 15:
      res = new LiveRoom();
      break;
    case 16:
      res = new Collage();
      break;
    case 17:
      res = new VideoNumber();
      break;
    case 18:
      res = new CustomerService();
      break;
    case 19:
      res = new PackageComponents();
      break;
    case 20:
      res = new CardItem();
      break;
    case 21:
      res = new ServiceGroup();
      break;
    case 22:
      res = new AllService();
      break;
    case 23:
      res = new ShopDetail();
      break;
    case 24:
      res = new Pay();
      break;
    case 25:
      res = new scrollBar();
      break;
  }
  return res;
};
