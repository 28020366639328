<!--
 * @Author: lsw lsw_0524@163.com
 * @Date: 2024-04-11 11:53:52
 * @LastEditors: lsw lsw_0524@163.com
 * @LastEditTime: 2024-04-11 16:22:35
 * @FilePath: /yiyun_project/src/views/addPages/uicomponents/BasicComponents/scrollBar/scrollBarControl.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="set_s_card">
      <div class="card_title">背景设置</div>
      <div class="set_item flex_start">
        <div>双色渐变</div>
        <div class="bigContent">
          <div class="content">
            <el-color-picker v-model="controlDefault.bgColorOne"></el-color-picker>
            <div class="blue-l" @click="returnbgColorOne">重置</div>
          </div>
          <div class="content">
            <el-color-picker v-model="controlDefault.bgColorTwo"></el-color-picker>
            <div class="blue-l" @click="returnbgColorTwo">重置</div>
          </div>
        </div>
      </div>
      <div class="card_title">圆角设置</div>
      <div class="set_item">
        <div>圆角弧度</div>
        <el-slider :min="0" :max="20" v-model="controlDefault.fillet"></el-slider>
      </div>
    </div>
    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import ToPage from '@/views/addPages/toPage/index.vue';
export default {
  props: {
    controlDefault: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ToPage,
  },
};
</script>
<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>
