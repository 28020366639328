<template>
  <div class="basicBox">
    <div class="set_s_card">
      <div class="card_title">文字设置</div>
      <div class="set_item">
        <div>页面名称</div>
        <el-input v-model="basicSet.pageName" placeholder="仅用于后台查找"></el-input>
      </div>
      <div class="set_item">
        <div>页面标题</div>
        <el-input v-model="pageTitle" placeholder="上线后此页面标题"></el-input>
      </div>
    </div>

    <div class="set_s_card">
      <div class="card_title">页面展示</div>
      <div class="set_item">
        <div>页面类型</div>
        <el-radio-group v-model="basicSet.pageClass">
          <el-radio :label="2">首页</el-radio>
          <el-radio :label="1">自定义页面</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item flex_start">
        <div>展示平台</div>
        <el-checkbox-group v-model="basicSet.showPlatform">
          <el-checkbox v-for="(item, index) in platform" :label="item.id" :key="index">{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>

    <div class="set_s_card l">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>标题背景色</div>
        <div class="content">
          <el-color-picker v-model="basicSet.titleBgColor"></el-color-picker>
          <div class="blue-l" @click="returnTBColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>标题颜色</div>
        <el-radio-group v-model="basicSet.titleColor">
          <el-radio :label="0">黑色</el-radio>
          <el-radio :label="1">白色</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>整体背景色</div>
        <div class="content">
          <el-color-picker v-model="basicSet.wholeBgColor"></el-color-picker>
          <div class="blue-l" @click="returnWholeBgColor">重置</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    basicSet: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      platform: [],
    };
  },
  created() {
    this.getPlatform();
  },
  methods: {
    returnTBColor() {
      this.basicSet.titleBgColor = null;
    },
    returnWholeBgColor() {
      this.basicSet.wholeBgColor = null;
    },
    getPlatform() {
      let that = this;
      this.$axios
        .post(this.$api.shopDiy.setInfo, {
          key: 'client_type',
        })
        .then(res => {
          if (res.code == 0) {
            that.platform = res.result.info.config;
          }
        });
    },
  },
  watch: {
    pageTitle(val) {
      this.$emit('sonMethod', val);
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../publicStyle.less);
</style>
