<template>
  <div class="groupList" :style="{ background: btnGroup.bgColor }">
    <div>
      <div class="groupItem" v-for="(item, index) in btnGroup.list" :key="index">
        <div :class="btnGroup.list.length == 5 ? 'fiveTop' : 'groupItemTop'">
          <img :src="item.imgUrl" alt="" />
        </div>
        <div class="groupItemBot" :style="{ width: btnGroup.list.length == 5 ? '60px' : '80px' }">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    btnGroup: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'btnGroup.layout': {
      handler(val, old) {
        let num = 1
        if (val == 3) num = 3
        if (val == 4) num = 4
        if (val == 5) num = 5
        if (this.btnGroup.list.length < num) {
          num -= this.btnGroup.list.length
          for (let i = 0; i < num; i++) {
            this.btnGroup.list.push({
              name: '按钮文字',
              imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
              toPage: '跳转页面'
            })
          }
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.groupList {
  width: 100%;
  padding: 3px 0;
  & > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 13px;
  }
  .groupItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
    .groupItemTop {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      font-size: 12px;
    }
    .fiveTop {
      width: 48px !important;
      height: 48px !important;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
    }
    .groupItemBot {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-shrink: 0;
      margin-top: 7px;
    }
  }
}
</style>
