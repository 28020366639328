<template>
  <div class="scrollBar">
    <div class="scrollContent" id="scrollContent" :style="{ background: `linear-gradient( 180deg, ${config.bgColorOne} 0%, ${config.bgColorTwo} 100%)`, borderRadius: `${config.fillet}px` }">
      <div class="personInfo" id="personInfo" v-for="(item, index) in person_list" :key="index">
        <div class="info">
          <img :src="item.hearder_img" alt="" />
          <div class="moblie">{{ item.moblie }}</div>
        </div>
        <div class="time">{{ item.time }}</div>
        <div class="remark">{{ item.remark }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { eyun_sass_admin } from '@/util/config.js';

export default {
  props: {
    config: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      personInfo: {
        hearder_img: eyun_sass_admin + 'diyComponentsImg/default_header_img.png',
        moblie: '138****9087',
        remark: '充值50元话费',
        time: '刚刚',
      },
      person_list: [],
    };
  },
  mounted() {
    for (let i = 0; i < 2; i++) {
      this.person_list.push(this.personInfo);
    }
  },
};
</script>

<style lang="less" scoped>
.scrollBar {
  width: 100%;
  height: 86px;
  padding: 1px 10px;
  .scrollContent {
    width: 100%;
    height: 100%;
    padding: 10px 14px;
    overflow: hidden;
    .personInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
      font-weight: 400;
      font-size: 12px;
      .info {
        display: flex;
        align-items: center;
        img {
          width: 26px !important;
          height: 26px !important;
          border-radius: 9999px;
          margin-right: 10px;
        }
        .moblie {
          color: #3d3d3d;
        }
      }
      .time {
        color: #999999;
      }
      .remark {
        color: #3a3a3a;
      }
    }
  }
}
</style>
