<template>
  <div class="goodsGroupControlBox">
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnColor">重置</div>
        </div>
      </div>
    </div>

    <div class="set_s_card">
      <div class="card_title">组件设置</div>
      <div class="set_item flex_start">
        <div>布局方式</div>
        <el-radio-group v-model="controlDefault.layout">
          <el-radio :label="0">左对齐</el-radio>
          <el-radio :label="1">右对齐</el-radio>
          <el-radio :label="2">居中</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>文章来源</div>
        <el-radio-group v-model="controlDefault.txtSource">
          <el-radio :label="0">选择文章</el-radio>
          <el-radio :label="1">选择分类</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item" v-show="controlDefault.txtSource == 1">
        <div>选择分类</div>
        <div class="content">
          <el-input disabled v-model="controlDefault.classify_name"></el-input>
          <div class="blue-l" @click="showClass">选择</div>
        </div>
      </div>
      <div class="set_item" v-show="controlDefault.txtSource == 1">
        <div>文章数量</div>
        <el-slider :min="0" :max="20" v-model="controlDefault.goodsNum" @change="changeNum"></el-slider>
      </div>
    </div>

    <div class="set_i_card" v-show="controlDefault.txtSource == 0">
      <div class="card_top">
        <div class="card_title">文章列表</div>
        <div class="editArea">
          <el-tooltip effect="light" content="添加文章" placement="top-end">
            <img style="margin-right: 8px" @click="addItem" v-show="controlDefault.txtSource == 0" :src="imgH + 'add.png'" alt="" />
          </el-tooltip>
          <el-popover placement="bottom" trigger="click">
            <div class="showImage">
              <div class="image" v-for="(item, index) in controlDefault.list" :key="index">
                <img :src="item.picture" alt="" />
                <div class="delMask" @click="deleteItem(index)"><i class="el-icon-delete"></i></div>
              </div>
            </div>
            <img slot="reference" :src="imgH + 'delete.png'" alt="" />
          </el-popover>
        </div>
      </div>
      <vuedraggable
        v-model="controlDefault.list"
        v-bind="{
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost',
          scroll: true,
        }"
      >
        <transition-group>
          <div class="chooseBtn" v-for="(item, index) in controlDefault.list" :key="index">
            <div class="imageBox">
              <img v-if="item.picture" :src="item.picture" alt="" />
              <div class="upload" @click="chooseTxt(index)">选择文章</div>
            </div>
            <div class="btnConfig">
              <div>
                <el-input placeholder="文章标题" disabled v-model="item.title"></el-input>
              </div>
              <div>
                <el-input placeholder="文章简介" v-model="item.subtitle" disabled></el-input>
              </div>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
    </div>
    <!-- 弹框 -->
    <el-dialog :title="style == 3 ? '选择文章' : '选择分类'" :visible.sync="showShop" width="52%">
      <ListComponents v-if="showShop" :styleMethon="style" @getListItem="getItem"></ListComponents>
    </el-dialog>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import ListComponents from '@/components/listComponents';
import { imgUrlHead } from '@/util/config.js';
export default {
  components: {
    vuedraggable,
    ListComponents,
  },
  props: {
    controlDefault: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
      categoryName: '',
      index: -1,
      style: 0,
      showShop: false,
    };
  },
  methods: {
    // 重置颜色
    returnColor() {
      this.controlDefault.bgColor = null;
    },
    // 显示分类弹框
    showClass() {
      this.style = 4;
      this.showShop = true;
    },
    // 更改最大的显示商品数量
    changeNum(newNum) {
      if (newNum > this.controlDefault.totalList.length) {
        this.$message({
          message: '不可超过该分类商品的最大数量',
          type: 'warning',
        });
        this.controlDefault.goodsNum = this.controlDefault.totalList.length;
      }
      this.controlDefault.list = [...this.controlDefault.totalList].splice(0, newNum);
    },
    // 点击新增商品
    addItem() {
      this.controlDefault.list.push({
        picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
        title: '文章标题',
        subtitle: '文章简介',
      });
    },
    // 删除商品
    deleteItem(index) {
      if (this.controlDefault.list.length <= 1) {
        this.$message.warning('至少添加一篇文章!!');
        return;
      }
      this.controlDefault.list.splice(index, 1);
    },
    // 选择商品
    chooseTxt(index) {
      this.index = index;
      this.style = 3;
      this.showShop = !this.showShop;
    },
    // 获取到选中的商品
    getItem(item) {
      this.showShop = false;
      let that = this;
      if (this.style == 3) {
        this.$set(this.controlDefault.list, this.index, item);
      } else {
        this.controlDefault.classify_id = item.id;
        this.controlDefault.classify_name = item.classify_name;
        this.$axios
          .post(this.$spi.set.listArticle, {
            page: 1,
            rows: 20,
            article_classify_id: item.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.controlDefault.totalList = JSON.parse(JSON.stringify(res.result.list));
              this.controlDefault.list = res.result.list;
              if (res.result.total_number <= 20) {
                that.controlDefault.goodsNum = res.result.total_number;
              } else {
                that.controlDefault.goodsNum = 20;
              }
            }
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
.content {
  flex-wrap: nowrap !important;
}
.chooseBtn {
  img {
    border-radius: 0 !important;
  }
  .btnConfig {
    width: 100%;
    & > div {
      width: 100%;
    }
  }
  .el-input {
    margin-right: 0 !important;
  }
}
</style>
