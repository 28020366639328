<template>
  <div>
    <el-table :data="categoryList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="classify_name" label="分类名称" align="center"></el-table-column>
      <el-table-column prop="sort" label="排序" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" size="small" @click="chooseCategory(row.classify_name, row.id)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </div>
</template>

<script>
import Paging from '@/components/paging'
export default {
  components: {
    Paging
  },
  data () {
    return {
      page: 1,
      rows: 10,
      total: 0,
      categoryList: [],
    };
  },
  created () {
    this.getCategory();
  },
  methods: {
    updateData (val, status) {
      if (status == 0) {
        this.rows = val
      } else {
        this.page = val
      }
      this.getCategory();
    },
    // 查询分类信息
    getCategory () {
      this.$axios
        .post(this.$api.beauty.service.serviceClassifyList, {
          page: this.page,
          rows: this.rows,
        })
        .then(res => {
          if (res.code == 0) {
            this.categoryList = res.result.list;
            this.total = res.result.total_number
          }
        });
    },
    // 选择分类
    chooseCategory (name, id) {
      this.$emit('getResult', id, name);
    },
  },
};
</script>

