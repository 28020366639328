<template>
  <div class="headerBgControlBox">
    <div class="set_s_card">
      <div class="card_title">背景设置</div>
      <div class="set_item flex_start">
        <div>背景设置</div>
        <el-radio-group class="noWarp" v-model="controlDefault.layout">
          <el-radio :label="0">单色</el-radio>
          <el-radio :label="1">双色渐变</el-radio>
          <el-radio :label="2">图片</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item" v-if="controlDefault.layout == 0">
        <div>单色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColorOne"></el-color-picker>
          <div class="blue-l" @click="returnbgColorOne">重置</div>
        </div>
      </div>
      <div class="set_item flex_start" v-if="controlDefault.layout == 1">
        <div>双色渐变</div>
        <div class="bigContent">
          <div class="content">
            <el-color-picker v-model="controlDefault.bgColorOne"></el-color-picker>
            <div class="blue-l" @click="returnbgColorOne">重置</div>
          </div>
          <div class="content">
            <el-color-picker v-model="controlDefault.bgColorTwo"></el-color-picker>
            <div class="blue-l" @click="returnbgColorTwo">重置</div>
          </div>
        </div>
      </div>
      <div class="set_item" v-if="controlDefault.layout == 2">
        <div>图片</div>
        <div class="content">
          <el-input class="disabledInput" disabled v-model="controlDefault.bgImg" placeholder="选择图片"></el-input>
          <div class="blue-l" @click="chooseImg(-1)">选择</div>
        </div>
      </div>
      <div class="tips" style="margin-top: 8px" v-if="controlDefault.layout == 2">*建议尺寸750px*380px</div>
    </div>

    <div class="set_s_card">
      <div class="card_title">搜索框设置</div>
      <div class="set_item">
        <div>文字位置</div>
        <el-radio-group v-model="controlDefault.searchConfig.layout">
          <el-radio :label="0">靠左</el-radio>
          <el-radio :label="1">居中</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>内部颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.searchConfig.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>文字字段</div>
        <el-input v-model="controlDefault.searchConfig.txt"></el-input>
      </div>
    </div>

    <div class="set_i_card">
      <div class="card_top">
        <div class="card_title">轮播图设置</div>
        <div class="editArea">
          <el-tooltip effect="light" content="添加banner图" placement="top-end">
            <img style="margin-right: 8px" @click="addBanner" :src="imgH + 'add.png'" alt="" />
          </el-tooltip>
          <el-popover placement="bottom" trigger="click">
            <div class="showImage">
              <div class="image" v-for="(item, index) in controlDefault.bannerConfig" :key="index">
                <img :src="item.imgURL" alt="" />
                <div class="delMask" @click="deleteItem(index)"><i class="el-icon-delete"></i></div>
              </div>
            </div>
            <img slot="reference" :src="imgH + 'delete.png'" alt="" />
          </el-popover>
        </div>
      </div>
      <div class="tips" style="margin-top: 8px">*建议尺寸686px*320px，上传图片不超过1M，支持 png、bmg、jpeg、jpg、gif</div>
      <vuedraggable
        v-model="controlDefault.bannerConfig"
        v-bind="{
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost',
          scroll: true,
        }"
      >
        <transition-group>
          <div class="chooseImage" v-for="(item, index) in controlDefault.bannerConfig" :key="index">
            <div class="imgBox">
              <img :src="item.imgURL" alt="" />
            </div>
            <div class="btnArea">
              <div class="blue-b" @click="chooseImg(index)">选择图片</div>
              <div class="blue-b" @click="choosePage(index)">选择路径</div>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
    </div>
    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
    <!-- 选择图片弹框 -->
    <UploadSource v-if="changeStatus" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag" @getSource="getImgRes" type="image" :maxSize="1024 * 1024"></UploadSource>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import UploadSource from '@/components/uploadSource';
import ToPage from '@/views/addPages/toPage/index.vue';
import { imgUrlHead } from '@/util/config.js';
export default {
  props: {
    controlDefault: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    vuedraggable,
    UploadSource,
    ToPage,
  },

  data() {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
      index: -1,
      toPageFlag: false,
      chooseImgFlag: false,
      isBgImg: 1,
    };
  },
  updated() {
    this.$emit('sonMethod', this.controlDefault);
  },
  methods: {
    changeStatus(val) {
      this.chooseImgFlag = val;
    },
    returnColor() {
      this.controlDefault.searchConfig.bgColor = null;
    },
    returnbgColorOne() {
      if (this.controlDefault.layout == 1) {
        this.controlDefault.bgColorOne = null;
      } else {
        this.controlDefault.bgColorOne = null;
      }
    },
    returnbgColorTwo() {
      if (this.controlDefault.layout == 1) {
        this.controlDefault.bgColorTwo = null;
      } else {
        this.controlDefault.bgColorTwo = null;
      }
    },
    getIndex(index) {
      this.index = index;
    },
    handleRemove(response) {
      if (response.code == 0) {
        this.controlDefault.bannerConfig[this.index].imgURL = response.result.path;
        this.controlDefault.bannerConfig[this.index].status = '0';
        this.$emit('sonMethod', this.controlDefault);
      }
    },
    addBanner() {
      if (this.controlDefault.bannerConfig.length == 5) {
        this.$message({
          message: '最多只可以添加五张banner图哦~',
          type: 'error',
        });
        return;
      }
      this.controlDefault.bannerConfig.push({
        status: '1',
        imgURL: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png',
        toPage: '跳转页面',
      });
    },
    // 点击移动banner图位置
    moveClick(status, index, item) {
      if (status == 0) {
        // 向上移
        if (index == 0) {
          this.$message({
            message: '已经在最前面了~',
            type: 'error',
          });
          return;
        }
        this.controlDefault.bannerConfig.splice(index, 1);
        this.controlDefault.bannerConfig.splice(index - 1, 0, item);
      } else {
        // 向下移
        if (index == this.controlDefault.bannerConfig.length - 1) {
          this.$message({
            message: '已经在最后面了~',
            type: 'error',
          });
          return;
        }
        this.controlDefault.bannerConfig.splice(index + 2, 0, item);
        this.controlDefault.bannerConfig.splice(index, 1);
      }
    },
    // 删除对应banner图
    deleteItem(index) {
      if (this.controlDefault.bannerConfig.length === 1) {
        this.$message.warning('至少保留一张banner');
        return;
      }
      this.controlDefault.bannerConfig.splice(index, 1);
      this.$emit('sonMethod', this.controlDefault, { action: 'delete', index });
    },
    // 选择图片
    chooseImg(index) {
      if (index != undefined) this.index = index;
      this.chooseImgFlag = !this.chooseImgFlag;
    },
    // 选择跳转路径
    choosePage(index) {
      this.index = index;
      this.toPageFlag = true;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    // 拿到跳转路径
    getPageRes(item) {
      this.controlDefault.bannerConfig[this.index].toPage = item.wxroute;
      this.toPageFlag = false;
    },
    // 拿到图片
    getImgRes(list) {
      if (this.index == -1) {
        this.controlDefault.bgImg = list[0].path;
      } else {
        this.controlDefault.bannerConfig[this.index].imgURL = list[0].path;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>
