<template>
  <div class="titleBarControl">
    <div class="set_s_card">
      <div class="card_title">组件设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnbgColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>对齐方向</div>
        <el-radio-group v-model="controlDefault.layout">
          <el-radio :label="0">居左</el-radio>
          <el-radio :label="1">居中</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>标题样式</div>
        <el-radio-group v-model="controlDefault.isSubTitle">
          <el-radio :label="0">图标文字</el-radio>
          <el-radio :label="1">标题副标题</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="set_i_card l">
      <div class="card_title">主标题设置</div>
      <div class="tips" v-if="!controlDefault.isSubTitle">*建议图片尺寸40px*40px</div>
      <div class="set_item" v-if="controlDefault.isSubTitle == 1">
        <div>主标题</div>
        <el-input v-model="controlDefault.contant" placeholder="标题内容"></el-input>
      </div>
      <div class="chooseBtn" id="chooseBtn" v-else>
        <div class="imageBox">
          <div class="img" v-if="controlDefault.titleImg">
            <img :src="controlDefault.titleImg" alt="" />
            <i class="iconfont icon-guanbi" @click="deleteImg"></i>
          </div>
          <div class="upload" @click="chooseImgFlag = true">选择图片</div>
        </div>
        <div class="btnConfig">
          <div>
            <el-input v-model="controlDefault.contant"></el-input>
            <div class="blue-b" @click="controlDefault.contant = ''">清空</div>
          </div>
          <div>
            <el-input v-model="controlDefault.toPage" disabled placeholder="请选择跳转路径"></el-input>
            <div class="blue-b" @click="showPageM()">选择</div>
          </div>
        </div>
      </div>
      <div class="set_item" v-if="controlDefault.isSubTitle == 1">
        <div>选择路径</div>
        <div class="content">
          <el-input style="flex: 1; margin-right: 16px" v-model="controlDefault.toPage" disabled placeholder="请选择跳转路径"></el-input>
          <div class="blue-l" @click="showPageM()">选择</div>
        </div>
      </div>
      <div class="set_item">
        <div>主标题颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.mainColor"></el-color-picker>
          <div class="blue-l" @click="returnbgMainColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>主标题字号</div>
        <div class="content">
          <el-select v-model="controlDefault.mainFontSize" placeholder="请选择主标题字号">
            <el-option v-for="item in fontSize" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <div class="blue-l" @click="returnMainFontSize">重置</div>
        </div>
      </div>
    </div>

    <div class="set_s_card l" v-show="controlDefault.isSubTitle == 1">
      <div class="card_title">副标题设置</div>
      <div class="set_item">
        <div>副标题</div>
        <el-input v-model="controlDefault.SubTitle" placeholder="标题内容"></el-input>
      </div>
      <div class="set_item">
        <div>副标题颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.secondColor"></el-color-picker>
          <div class="blue-l" @click="returnbgSecondColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>副标题字号</div>
        <div class="content">
          <el-select v-model="controlDefault.secondFontSize" placeholder="请选择主标题字号">
            <el-option v-for="item in fontSize" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <div class="blue-l" @click="returnSecondFontSize">重置</div>
        </div>
      </div>
    </div>

    <el-dialog title="选择链接" :visible.sync="showPage" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
    <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag" @getSource="getImgRes"></UploadSource>
  </div>
</template>

<script>
import ToPage from '@/views/addPages/toPage/index.vue';
import UploadSource from '@/components/uploadSource';
export default {
  props: {
    controlDefault: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ToPage,
    UploadSource,
  },
  data() {
    return {
      showPage: false,
      chooseImgFlag: false,
      index: -1,
      fontSize: [12, 14, 16, 18, 20, 22, 24],
    };
  },
  methods: {
    changeStatus(val) {
      this.chooseImgFlag = val;
    },
    returnbgColor() {
      this.controlDefault.bgColor = null;
    },
    returnbgMainColor() {
      this.controlDefault.mainColor = '#333';
    },
    returnbgSecondColor() {
      this.controlDefault.secondColor = '#666';
    },
    returnMainFontSize() {
      this.controlDefault.mainFontSize = 18;
    },
    returnSecondFontSize() {
      this.controlDefault.secondFontSize = 14;
    },
    deleteImg() {
      this.controlDefault.titleImg = '';
    },
    // 选择图片
    chooseImg() {
      this.chooseImgFlag = !this.chooseImgFlag;
    },
    getImgRes(imglist) {
      this.controlDefault.titleImg = imglist[0].path;
    },
    showPageM() {
      this.showPage = true;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    // 拿到跳转路径
    getPageRes(item) {
      this.controlDefault.toPage = item.wxroute;
      this.showPage = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
#chooseBtn {
  margin-bottom: 16px;

  .imageBox {
    padding-top: 10px;
    display: flex;
    justify-content: center;
  }
  .img {
    position: relative;
  }
  .img,
  img {
    width: 40px !important;
    height: 40px !important;
    border-radius: 0 !important;
  }
  .iconfont {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    cursor: pointer;
  }
}
</style>
