<template>
  <div class="goodsGroupControlBox">
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>价格颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.priceColor"></el-color-picker>
          <div class="blue-l" @click="returnPriceColor">重置</div>
        </div>
      </div>
    </div>

    <div class="set_s_card">
      <div class="card_title">组件设置</div>
      <div class="set_item flex_start">
        <div>布局方式</div>
        <el-radio-group v-model="controlDefault.layout">
          <el-radio :label="0">单列显示</el-radio>
          <el-radio :label="1">双列显示</el-radio>
          <el-radio :label="2">三列显示</el-radio>
          <el-radio :label="3">列表显示</el-radio>
          <el-radio :label="4">单行滚动</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>服务价格</div>
        <el-radio-group v-model="controlDefault.showPrice">
          <el-radio :label="0">显示</el-radio>
          <el-radio :label="1">不显示</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="set_i_card">
      <div class="card_top" style="margin-bottom: 24px">
        <div class="card_title">服务设置</div>
        <div class="editArea">
          <el-tooltip effect="light" content="添加服务" placement="top-end">
            <img style="margin-right: 8px" @click="addItem" v-show="controlDefault.serviceSource == 0"
              :src="imgH + 'add.png'" alt="" />
          </el-tooltip>
          <el-popover placement="bottom" trigger="click">
            <div class="showImage">
              <div class="image" v-for="(item, index) in controlDefault.list" :key="index">
                <img :src="item.cover_picture" alt="" />
                <div class="delMask" @click="deleteItem(index)"><i class="el-icon-delete"></i></div>
              </div>
            </div>
            <img slot="reference" v-show="controlDefault.serviceSource == 0" :src="imgH + 'delete.png'" alt="" />
          </el-popover>
        </div>
      </div>
      <div class="set_item">
        <div>服务来源</div>
        <el-radio-group v-model="controlDefault.serviceSource">
          <el-radio :label="0">选择服务</el-radio>
          <el-radio :label="1">选择分类</el-radio>
        </el-radio-group>
      </div>
      <vuedraggable v-show="!controlDefault.serviceSource" v-model="controlDefault.list" v-bind="{
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        scroll: true,
      }">
        <transition-group>
          <div class="chooseImage" id="chooseImage" v-for="(item, index) in controlDefault.list" :key="index">
            <div class="imgBox">
              <img :src="item.cover_picture" alt="" />
              <div class="upload" @click="chooseGoods(index)">选择服务</div>
            </div>
            <div class="btnArea">
              <el-input v-model="item.service_name" disabled placeholder="服务名称"></el-input>
              <el-input v-model="item.service_price" disabled placeholder="服务价格"></el-input>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
      <div class="set_item" v-show="controlDefault.serviceSource == 1">
        <div>选择分类</div>
        <div class="content">
          <el-input disabled v-model="controlDefault.serviceStyle_name"></el-input>
          <div class="blue-l" @click="showCategoryLog">选择</div>
        </div>
      </div>
      <div class="set_item" v-show="controlDefault.serviceStyle_id != '' && controlDefault.serviceSource == 1">
        <div>服务数量</div>
        <el-slider :min="0" :max="20" v-model="controlDefault.serviceNum" @change="changeNum"></el-slider>
      </div>
    </div>

    <!-- 服务分类弹框 -->
    <el-dialog title="选择分类" :visible.sync="showCategory" width="52%">
      <div>
        <ChooseCategory @getResult="getClass"></ChooseCategory>
      </div>
    </el-dialog>
    <!-- 服务弹框 -->
    <el-dialog title="选择服务" :visible.sync="showGoods" width="52%">
      <ListComponents v-if="showGoods" :styleMethon="style" @getListItem="getItem"></ListComponents>
    </el-dialog>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import ListComponents from '@/components/listComponents';
import ChooseCategory from '../components/chooseCategory.vue';
import { imgUrlHead } from '@/util/config.js';
export default {
  components: {
    vuedraggable,
    ListComponents,
    ChooseCategory,
  },
  props: {
    controlDefault: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
      showCategory: false,
      serviceNum: -1,
      showGoods: false,
      index: -1,
      style: 10,
      total: 0,
      totalList: [],
    };
  },
  methods: {
    // 重置颜色
    returnColor () {
      this.controlDefault.bgColor = null;
    },
    returnPriceColor () {
      this.controlDefault.priceColor = null;
    },
    // 显示分类弹框
    showCategoryLog () {
      this.showCategory = true;
    },
    // 点击新增服务
    addItem () {
      this.controlDefault.list.push({
        status: '1',
        cover_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/620zheng.png',
        service_name: '服务名称',
        service_price: '0.01',
      });
    },
    // 删除服务
    deleteItem (index) {
      if (this.controlDefault.list.length <= 1) {
        this.$message.warning('至少添加一个服务!!');
        return;
      }
      this.controlDefault.list.splice(index, 1);
    },
    // 选择服务
    chooseGoods (index) {
      this.index = index;
      this.showGoods = !this.showGoods;
    },
    // 获取到选中的服务
    getItem (item) {
      this.showGoods = false;
      console.log(item)
      this.$set(this.controlDefault.list, this.index, item);
    },
    // 获取分类
    getClass (id, name) {
      this.controlDefault.serviceStyle_id = id;
      this.controlDefault.serviceStyle_name = name;
      let that = this;
      this.$axios
        .post(this.$api.beauty.service.serviceList, {
          page: 1,
          rows: 20,
          classify_id: id,
        })
        .then(res => {
          if (res.code == 0) {
            that.controlDefault.totalList = JSON.parse(JSON.stringify(res.result.list));
            that.controlDefault.list = res.result.list;
            if (res.result.total_number <= 20) {
              that.controlDefault.serviceNum = res.result.total_number;
            } else {
              that.controlDefault.serviceNum = 20;
            }
          }
        });
      this.showCategory = false;
    },
    changeNum (newNum) {
      if (newNum > this.controlDefault.totalList.length) {
        this.$message({
          message: '不可超过该分类服务的最大数量',
          type: 'warning',
        });
        this.controlDefault.serviceNum = this.controlDefault.totalList.length;
      }
      this.controlDefault.list = [...this.controlDefault.totalList].splice(0, newNum);
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>
