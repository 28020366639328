<template>
  <div class="videoControl">
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnColor">重置</div>
        </div>
      </div>
    </div>

    <div class="set_s_card">
      <div class="card_title">标题设置</div>
      <div class="set_item">
        <div>视频标题</div>
        <el-input v-model="controlDefault.videoTitle"></el-input>
      </div>
    </div>

    <div class="set_i_card">
      <div class="card_title">视频配置</div>
      <div class="tips" style="margin-bottom: 16px">*上传视频尺寸要求343*200，封面尺寸343*200，支持类型mp4(限制20M以内)</div>
      <div class="chooseImage">
        <div class="imgBox">
          <img :src="controlDefault.imgUrl" alt="" />
        </div>
        <div class="btnArea">
          <div class="blue-b" @click="chooseImg(index)">选择封面</div>
          <div class="blue-b" @click="chooseVideoFlag = true">选择视频</div>
        </div>
      </div>
    </div>
    <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag" @getSource="getImgRes"></UploadSource>
    <UploadSource v-if="chooseVideoFlag" @changeStatus="changeVideoStatus" :dialogVisible="chooseVideoFlag" @getSource="getVideoRes" type="video" :maxSize="1024 * 1024 * 20"></UploadSource>
  </div>
</template>

<script>
import UploadSource from '@/components/uploadSource';
import { imgUrlHead } from '@/util/config.js';
export default {
  props: {
    controlDefault: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    UploadSource,
  },
  data() {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
      chooseImgFlag: false,
      chooseVideoFlag: false,
    };
  },

  methods: {
    changeStatus(val) {
      this.chooseImgFlag = val;
    },
    changeVideoStatus(val) {
      this.chooseVideoFlag = val;
    },
    chooseImg() {
      this.chooseImgFlag = true;
    },
    getImgRes(imglist) {
      this.controlDefault.imgUrl = imglist[0].path;
    },
    getVideoRes(videolist) {
      this.controlDefault.videoUrl = videolist[0].path;
    },
    // 重置颜色
    returnColor() {
      this.controlDefault.bgColor = null;
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>
