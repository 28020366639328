<template>
  <div class="style_5" :style="{ backgroundColor: config.bgColor }">
    <div class="content" :style="{ backgroundColor: choose_s_color.secondary_color }">
      <div>
        <div class="imgBox">
          <img :src="config.img_1" alt="">
        </div>
        <div class="bot">
          <div class="title" :style="{ color: config.titleColor }">{{ config.txt_1 }}</div>
          <div class="subTitle" :style="{ color: choose_s_color.secondary_color }">{{ config.txt_2 }}</div>
          <div class="botBox">
            <div class="btn" :style="{ backgroundColor: choose_s_color.main_color }">{{ config.txt_3 }}<span></span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="imgBox">
          <img :src="config.img_2" alt="">
        </div>
        <div class="bot">
          <div class="title" :style="{ color: config.titleColor }">{{ config.txt_4 }}</div>
          <div class="subTitle" :style="{ color: choose_s_color.secondary_color }">{{ config.txt_5 }}</div>
          <div class="botBox">
            <div class="btn" :style="{ backgroundColor: choose_s_color.main_color }">{{ config.txt_6 }}<span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => { }
    },
    choose_s_color: {
      type: Object,
      default: () => { }
    }
  }
}
</script>

<style lang="less" scoped>
.style_5 {
  min-width: 375px;
  padding: 6px 14px;

  .content {
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>div {
      &:first-child {
        margin-right: 10px;
      }

      flex: 1;
      flex-shrink: 0;
      max-width: 154px;
      height: 242px;
      background-color: #fff;
      border-radius: 5px 5px 5px 5px;
      //display: flex;
      //flex-direction: column;
      overflow: hidden;

      .imgBox {
        width: 154px;
        height: 154px;
        //flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .bot {
        flex: 1;
        padding: 10px 10px 10px 18px;
      }

      .title {
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .subTitle {
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        font-size: 12px;
        margin-top: 2px;
        margin-bottom: 10px;
      }

      .botBox {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .btn {
        padding: 5px 10px 5px 18px;
        font-size: 12px;
        color: #fff;
        border-radius: 15px 15px 15px 15px;
        display: inline-flex;
        align-items: center;
        text-align: center;

        span {
          display: inline-block;
          border: 8px solid #fff;
          border-color: transparent transparent transparent #fff;
          margin-left: 3px;
        }
      }
    }
  }
}
</style>