<template>
  <div class="setMealBox">
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>套餐色系</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.colorSystem"></el-color-picker>
          <div class="blue-l" @click="returnColorSystem">重置</div>
        </div>
      </div>
    </div>
    <div class="set_s_card">
      <div class="card_title">字段设置</div>
      <div class="set_item">
        <div>标签字段</div>
        <el-input v-model="controlDefault.tag_txt"></el-input>
      </div>
      <div class="set_item">
        <div>加购字段</div>
        <el-input :maxlength="4" v-model="controlDefault.add_txt"></el-input>
      </div>
    </div>
    <div class="set_i_card">
      <div class="card_top" style="margin-bottom: 24px">
        <div class="card_title">套餐列表</div>
        <div class="editArea">
          <el-tooltip effect="light" content="添加套餐" placement="top-end">
            <img style="margin-right: 8px" @click="addItem" :src="imgH + 'add.png'" alt="" />
          </el-tooltip>
          <el-popover placement="bottom" trigger="click">
            <div class="showImage">
              <div class="image" v-for="(item, index) in controlDefault.list" :key="index">
                <img :src="item.cover_picture" alt="" />
                <div class="delMask" @click="deleteItem(index)"><i class="el-icon-delete"></i></div>
              </div>
            </div>
            <img slot="reference" :src="imgH + 'delete.png'" alt="" />
          </el-popover>
        </div>
      </div>
      <vuedraggable v-model="controlDefault.list" v-bind="{
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        scroll: true,
      }">
        <transition-group>
          <div class="chooseImage" id="chooseImage" v-for="(item, index) in controlDefault.list" :key="index">
            <div class="imgBox">
              <img :src="item.cover_picture" alt="" />
              <div class="upload" @click="chooseGoods(index)">选择套餐</div>
            </div>
            <div class="btnArea">
              <el-input v-model="item.package_name" disabled placeholder="套餐名称"></el-input>
              <el-input v-model="item.wxroute" disabled placeholder="跳转路由"></el-input>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
    </div>
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes" type="setMeal"></ToPage>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import ToPage from '@/views/addPages/toPage/index.vue';
import { imgUrlHead } from '@/util/config.js';
export default {
  components: {
    vuedraggable,
    ToPage
  },
  props: {
    controlDefault: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
      toPageFlag: false,
      activeIndex: -1,
    }
  },
  methods: {
    // 重置色系
    returnColorSystem () {
      this.controlDefault.colorSystem = '#b0917f';
    },
    // 添加套餐
    addItem () {
      this.controlDefault.list.push({ cover_picture: "https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png", package_name: "套餐名称套餐名称套餐名称套餐名称套餐名称", type: 1, package_price: '', wxroute: '' })
    },
    // 删除套餐
    deleteItem (index) {
      if (this.controlDefault.list.length <= 1) {
        this.$message.warning('请至少保留一个套餐')
        return
      }
      this.controlDefault.list.splice(index, 1)
    },
    // 开启套餐选择
    chooseGoods (index) {
      this.activeIndex = index;
      this.toPageFlag = true;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
        this.$refs.toPage.chooseIndex('package');
      });
    },
    // 获取选中的套餐
    getPageRes (data) {
      this.$set(this.controlDefault.list, this.activeIndex, data);
      this.toPageFlag = false;
    }
  }
}
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>