<template>
  <div class="txtGroupBox" :style="{ background: TxtGroup.bgColor }">
    <div v-if="TxtGroup.layout == 0 || TxtGroup.layout == 1">
      <div class="botList" v-for="(item, index) in TxtGroup.list" :key="index">
        <div class="itemBox" v-if="TxtGroup.layout == 0">
          <div class="left">
            <img :src="item.picture" alt="" />
          </div>
          <div class="right">
            <p class="title">{{ item.title }}</p>
            <p style="color: #999999">{{ `${getDate(item.pubdate * 1000).month}月${getDate(item.pubdate * 1000).day}日` }}</p>
          </div>
        </div>
        <div class="itemBox" v-if="TxtGroup.layout == 1">
          <div class="right" style="margin-left: 0; margin-right: 10px">
            <p class="title">{{ item.title }}</p>
            <p style="color: #999999">{{ `${getDate(item.pubdate * 1000).month}月${getDate(item.pubdate * 1000).day}日` }}</p>
          </div>
          <div class="left">
            <img :src="item.picture" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div v-for="(item, index) in TxtGroup.list" :key="index">
      <div class="centerBox" v-if="TxtGroup.layout == 2 && index === 0">
        <div class="centerItem">
          <div class="imgBox">
            <img :src="item.picture" alt="" />
            <div class="centerTitle">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div v-if="TxtGroup.layout == 2 && index !== 0" class="imgBox1">
        <div class="right">
          <p class="title">{{ item.title }}</p>
          <p style="color: #999999">{{ `${getDate(item.pubdate * 1000).month}月${getDate(item.pubdate * 1000).day}日` }}</p>
        </div>
        <div class="left">
          <img :src="item.picture" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getDate from '@/util/getDate.js'
export default {
  props: {
    TxtGroup: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      getDate: getDate.getDate
    }
  }
}
</script>

<style lang="less" scoped>
.txtGroupBox {
  width: 100%;
  padding: 3px 0;
  // .topBanner {
  //   position: relative;
  //   .imgBox {
  //     width: 100%;
  //     height: 200px;
  //     border-radius: 8px;
  //     overflow: hidden;
  //   }
  //   .titleBox {
  //     width: 100%;
  //     height: 40px;
  //     background-color: rgba(0, 0, 0, 0.3);
  //     position: absolute;
  //     bottom: 0px;
  //     left: 0px;
  //     padding: 0 10px;
  //     line-height: 40px;
  //     color: #efefef;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  //     border-radius: 0 0 8px 8px;
  //   }
  // }
  .botList {
    // margin-top: 5px;
    padding: 11px 16px;
    .itemBox {
      display: flex;
      .left {
        width: 142px;
        height: 80px;
        border-radius: 4px;
        overflow: hidden;
        flex-shrink: 0;
      }
      .right {
        flex-shrink: 0;
        width: calc(100% - 152px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;
        p {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
        }
        .title {
          white-space: normal;
          font-size: 14px;
          font-weight: 600;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
  .centerBox {
    padding: 0 16px;
    margin-bottom: 10px;
    .centerItem {
      width: 100%;
      height: 200px;
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;
      .imgBox {
        width: 100%;
        height: 100%;
        position: relative;
        .centerTitle {
          font-size: 16px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 40px;
          line-height: 40px;
          padding: 0 15px 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #fff;
          // background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.3));
          background-image: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/p_firstIcon.png');
          background-size: 100% 100%;
        }
      }
    }
  }
  .imgBox1 {
    display: flex;
    padding: 11px 16px;
    .left {
      width: 142px;
      height: 80px;
      border-radius: 4px;
      overflow: hidden;
      flex-shrink: 0;
    }
    .right {
      flex-shrink: 0;
      width: calc(100% - 152px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
      }
      .title {
        white-space: normal;
        font-size: 14px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
}
</style>
