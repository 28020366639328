<template>
  <div class="bannerBox" :style="{ background: bannerArr.bgColor }">
    <div :style="{ margin: bannerArr.chooseMargin == 0 ? '0px' : '0 13px' }">
      <div
        class="img"
        :style="{
          width: bannerArr.chooseMargin == 0 ? '371px' : '345px',
          borderRadius: bannerArr.chooseMargin == 0 ? '' : '10px'
        }"
      >
        <div class="imgBox" :id="id">
          <div v-for="(item, index) in bannerArr.list" :key="index">
            <img :src="item.imgURL" alt="" />
          </div>
        </div>
      </div>
      <div class="spotBox" v-if="bannerArr.list.length > 1">
        <div
          @click="moveImg(index)"
          :class="['spot', spotActive == index ? 'activeSpot' : '']"
          v-for="(item, index) in bannerArr.list.length"
          :key="item"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerArr: {
      type: Object,
      default: () => {}
    },
    action: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: () => {}
    }
  },
  data() {
    return {
      imgArr: [],
      toNum: 0,
      spotActive: 0,
      id: 'imgBox',
      movedistance: null
    }
  },
  created() {
    this.id = this.id + this.index
    this.movedistance = this.bannerArr.chooseMargin == 0 ? 371 : 345
  },
  methods: {
    moveImg(i) {
      this.spotActive = i
      this.toNum = i * this.movedistance
      document.querySelector(`#${this.id}`).style.transform = `translateX(-${this.toNum}px)`
    }
  },
  watch: {
    action: {
      handler(val, old) {
        if (val.action == 'delete') {
          if (this.spotActive < val.index) {
            //
          } else if (this.spotActive == val.index) {
            if (val.index == 1 && this.bannerArr.list.length == 1) {
              document.querySelector(`#${this.id}`).style.transform = `translateX(0px)`
              this.spotActive--
            } else if (val.index == this.bannerArr.list.length) {
              this.toNum -= this.movedistance
              document.querySelector(`#${this.id}`).style.transform = `translateX(-${this.toNum}px)`
              this.spotActive--
            }
          } else {
            this.toNum -= this.movedistance
            document.querySelector(`#${this.id}`).style.transform = `translateX(-${this.toNum}px)`
            this.spotActive--
          }
        }
      },
      deep: true,
      immediate: true
    },
    spotActive(val) {
      this.$nextTick(() => {
        document.getElementById(this.id).style.height =
          document.getElementById(this.id).children[val].children[0].clientHeight + 'px'
      })
    },
    bannerArr: {
      handler() {
        this.$nextTick(() => {
          setTimeout(() => {
            document.getElementById(this.id).style.height =
              document.getElementById(this.id).children[this.spotActive].children[0].clientHeight +
              'px'
          }, 200)
        })
      },
      deep: true
    },
    'bannerArr.chooseMargin': {
      handler(val) {
        this.movedistance = this.bannerArr.chooseMargin == 0 ? 371 : 345
        this.$nextTick(() => {
          document.querySelector(`#${this.id}`).style.transform = `translateX(-${
            this.spotActive * this.movedistance
          }px)`
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.bannerBox {
  width: 100%;
  position: relative;
  padding: 0;
  .img {
    width: 100%;
    overflow: hidden;

    .imgBox {
      display: flex;
      transition: 0.3s;
      & > div {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
      }
    }
  }
  .spotBox {
    position: absolute;
    bottom: 10px;
    left: 50%;
    display: flex;
    transform: translateX(-50%);
    z-index: 100;
    .spot {
      display: block;
      width: 16px;
      height: 5px;
      padding: 0;
      color: transparent;
      font-size: 0;
      background: rgba(0, 0, 0, 0.5);
      border: 0;
      border-radius: 1px;
      outline: none;
      cursor: pointer;
      margin-right: 5px;
      transition: all 0.5s;
    }
    .activeSpot {
      width: 24px;
      background: #333;
      opacity: 1;
    }
  }
}
</style>
