<template>
  <div class="bigBox">
    <div class="set_s_card l">
      <div class="card_title">组件设置</div>
      <div class="set_item">
        <div>通知图标</div>
        <el-radio-group v-model="controlDefault.videoNumberIconStyle">
          <el-radio :label="0">默认图标</el-radio>
          <el-radio :label="1">上传图片</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item flex_start" v-show="controlDefault.videoNumberIconStyle == 1">
        <div>选择图片</div>
        <div class="content">
          <div class="tips">*建议图片尺寸48px*48px</div>
          <div class="operationBox">
            <div class="imgBox">
              <img v-if="controlDefault.uploadImgUrl != ''" :src="controlDefault.uploadImgUrl" alt="" />
              <div class="upload" @click="() => (chooseImgFlag = true)">选择图片</div>
            </div>
            <div class="del-l" @click="() => (controlDefault.uploadImgUrl = '')">清除</div>
          </div>
        </div>
      </div>
      <div class="set_item">
        <div>视频号ID</div>
        <div class="content">
          <el-input placeholder="请输入与小程序主体一致的视频号ID" v-model="controlDefault.videoNumberId"></el-input>
          <el-button type="text">获取视频号ID</el-button>
        </div>
      </div>
      <div class="set_item">
        <div>显示时间</div>
        <el-radio-group v-model="controlDefault.showTime">
          <el-radio :label="0">一直显示</el-radio>
          <el-radio :label="1">仅直播显示</el-radio>
        </el-radio-group>
      </div>
    </div>

    <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag" @getSource="getImgRes"></UploadSource>
  </div>
</template>

<script>
import UploadSource from '@/components/uploadSource';
export default {
  components: {
    UploadSource,
  },
  props: {
    controlDefault: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chooseImgFlag: false,
    };
  },
  methods: {
    chooseImg() {
      this.chooseImgFlag = true;
    },
    changeStatus(val) {
      this.chooseImgFlag = val;
    },
    getImgRes(val) {
      this.controlDefault.uploadImgUrl = val[0].path;
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
.operationBox {
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: flex-end;
  .imgBox {
    width: 88px;
    height: 88px;
    background-color: #fff;
    border-radius: 5px;
    margin-right: 16px;
    .flex();
    img {
      width: 80px !important;
      height: 80px !important;
    }
    position: relative;
    .upload {
      cursor: pointer;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 25px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 0px 0px 5px 5px;
      .title(14px,#fff,400);
      .flex();
    }
  }
}
</style>
