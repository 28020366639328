<template>
  <div class="liveRoomControl">
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnBgColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>字体颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.fontColor"></el-color-picker>
          <div class="blue-l" @click="returnFontColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>按钮颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.btnColor"></el-color-picker>
          <div class="blue-l" @click="returnBtnColor">重置</div>
        </div>
      </div>
    </div>

    <div class="set_s_card">
      <div class="card_title">组件设置</div>
      <div class="set_item">
        <div>组件样式</div>
        <el-radio-group v-model="controlDefault.layout">
          <el-radio :label="0">带有商品</el-radio>
          <el-radio :label="1">直播主图</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>直播间</div>
        <div class="content">
          <el-input v-model="controlDefault.liveRoomInfo.title" disabled placeholder="请选择直播间"></el-input>
          <el-button type="primary" @click="chooseLiveRoomFn">选择</el-button>
        </div>
      </div>
    </div>

    <!-- <div class="congfigItem">
      <div class="configTitle">按钮字体颜色：</div>
      <div class="configContent">
        <el-color-picker v-model="controlDefault.btnFontColor"></el-color-picker>
        <el-button type="primary" @click="returnBtnFontColor">重置</el-button>
      </div>
    </div> -->
    <el-dialog title="选择直播间" :visible.sync="chooseLiveRoomFlag" width="500">
      <div class="searchBox">
        <div class="searchItem">
          <div class="title">搜索直播间：</div>
          <div class="content">
            <el-input v-model="searchName" placeholder="请输入直播间名称"></el-input>
          </div>
        </div>
        <el-button type="primary" @click="getLiveList(1)">搜索</el-button>
      </div>
      <el-table :cell-style="() => 'text-align:center'" :data="liveRoomList" :header-cell-style="{ 'background-color': '#F8F9FA', 'text-align': 'center' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="date" label="直播间封面">
          <template v-slot="{ row }">
            <div class="coverImg"><img :src="row.cover_img_url" alt="" /></div>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="直播间标题"></el-table-column>
        <el-table-column prop="room_id" label="房间号"></el-table-column>
        <el-table-column prop="date" label="直播商品数">
          <template v-slot="{ row }">
            {{ !row.goods_num ? 0 : row.goods_num }}
          </template>
        </el-table-column>
        <el-table-column prop="date" label="直播状态">
          <template v-slot="{ row }">
            {{
              row.live_status == 101
                ? '直播中'
                : row.live_status == 102
                ? '未开始'
                : row.live_status == 103
                ? '已结束'
                : row.live_status == 104
                ? '禁播'
                : row.live_status == 105
                ? '暂停'
                : row.live_status == 107
                ? '已过期'
                : '异常'
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="{ row }">
            <p v-if="JSON.stringify(controlDefault.liveRoomInfo) === JSON.stringify(row)">已选中</p>
            <el-button v-else type="text" @click="chooseFn(row)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="total" :page="page" :pageNum="row" @updatePageNum="updatePageNum"></Paging>
    </el-dialog>
  </div>
</template>

<script>
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  props: {
    controlDefault: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chooseLiveRoomFlag: false,
      searchName: '',
      liveRoomList: [],
      page: 1,
      row: 10,
      total: 0,
    };
  },
  methods: {
    returnBgColor() {
      this.controlDefault.bgColor = null;
    },
    returnFontColor() {
      this.controlDefault.fontColor = '#333';
    },
    returnBtnColor() {
      this.controlDefault.btnColor = '#FF4F58';
    },
    returnBtnFontColor() {
      this.controlDefault.btnFontColor = '#fff';
    },
    chooseLiveRoomFn() {
      this.getLiveList();
      this.chooseLiveRoomFlag = true;
    },
    // 获取直播间列表
    getLiveList(status) {
      if (status) {
        this.page = 1;
      }
      let that = this;
      this.$axios
        .post(this.$api.push.liveRoom.liveBookList, {
          page: this.page,
          rows: this.row,
          keyword: this.searchName,
          status: this.liveStatus === -1 ? null : this.liveStatus,
        })
        .then(res => {
          if (res.result.total !== 0) {
            that.liveRoomList = res.result.list;
            that.total = res.result.total;
          }
        });
    },
    // 修改分页数据
    updatePageNum(val, status) {
      if (status == 0) {
        this.row = val;
      } else {
        this.page = val;
      }
      // 重新获取数据
      this.getLiveList();
    },
    // 弹框选中直播间
    chooseFn(row) {
      let that = this;
      this.$axios
        .post(this.$api.push.liveRoom.getLiveGoodList, {
          page: 1,
          rows: 4,
          room_id: row.room_id,
        })
        .then(res => {
          if (res.code === 0) {
            that.controlDefault.liveRoomInfo = row;
            that.controlDefault.liveRoomInfo.goods = res.result.list;
            that.chooseLiveRoomFlag = false;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
.liveRoomControl {
  padding: 20px;

  .searchBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .searchItem {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .el-input {
        width: 200px;
      }
    }
  }
  .coverImg {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 80px !important;
      height: 80px !important;
      margin-right: 10px;
    }
  }
}
</style>
