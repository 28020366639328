<template>
  <div class="BIgBOX indexBox11">
    <!-- 头部组件 -->
    <HeaderBgControl v-if="controlIndex == 0" :controlDefault="controlDefault" @sonMethod="getInfo"></HeaderBgControl>
    <!-- 轮播图组件 -->
    <BannerControl v-if="controlIndex == 1" :controlDefault="controlDefault" @getImg="getInfo"></BannerControl>
    <!-- 搜索框 -->
    <SearchControl v-if="controlIndex == 2" :controlDefault="controlDefault" @getSearchBox="getInfo"></SearchControl>
    <!-- 按钮组 -->
    <BtnGroupControl v-if="controlIndex == 3" :controlDefault="controlDefault" @sonMethod="getInfo"></BtnGroupControl>
    <!-- 图片橱窗 -->
    <PictureWindowControl v-if="controlIndex == 4" :controlDefault="controlDefault" @sonMethod="getInfo"></PictureWindowControl>
    <!-- 富文本 -->
    <RichTextControl v-if="controlIndex == 5" :controlDefault="controlDefault" @sonMethod="getInfo"></RichTextControl>
    <!-- 公告 -->
    <NoticeControl v-if="controlIndex == 6" :controlDefault="controlDefault" @sonMethod="getInfo"></NoticeControl>
    <!-- 视频 -->
    <VideoControl v-if="controlIndex == 7" :controlDefault="controlDefault" @sonMethod="getInfo"></VideoControl>
    <!-- 商品组 -->
    <GoodsGroupControl v-if="controlIndex == 8" :controlDefault="controlDefault" @sonMethod="getInfo"></GoodsGroupControl>
    <!-- 商户组 -->
    <ShopGroupControl v-if="controlIndex == 9" :controlDefault="controlDefault" @sonMethods="getInfo"></ShopGroupControl>
    <!-- 文章组 -->
    <TxtGroupControl v-if="controlIndex == 10" :controlDefault="controlDefault" @sonMethod="getInfo"></TxtGroupControl>
    <!-- 标题栏 -->
    <TitleBarControl v-if="controlIndex == 11" :controlDefault="controlDefault" @sonMethod="getInfo"></TitleBarControl>
    <!-- 辅助线 -->
    <GuideControl v-if="controlIndex == 12" :controlDefault="controlDefault" @sonMethod="getInfo"></GuideControl>
    <!-- 所有商品 -->
    <AllGoodsControl v-if="controlIndex == 13" :controlDefault="controlDefault" @sonMethod="getInfo"></AllGoodsControl>
    <!-- 基础设置 -->
    <BasicSetting v-if="controlIndex == -1" :pageTitle="pageTitle" :basicSet="basicSet" @sonMethod="getBasicSet"></BasicSetting>
    <!-- 优惠券组件 -->
    <CouponControl v-if="controlIndex == 14" :controlDefault="controlDefault" @sonMethod="getInfo"></CouponControl>
    <!-- 直播间组件 -->
    <LiveRoom v-if="controlIndex == 15" :controlDefault="controlDefault" @sonMethod="getInfo"></LiveRoom>
    <!-- 拼团组件 -->
    <Collage v-if="controlIndex == 16" :controlDefault="controlDefault" @sonMethod="getInfo"></Collage>
    <!-- 微信视频号组件 -->
    <VideoNumberControl v-if="controlIndex == 17" :controlDefault="controlDefault" @sonMethod="getInfo"></VideoNumberControl>
    <!-- 客服设置组件 -->
    <CustomerServiceControl v-if="controlIndex == 18" :controlDefault="controlDefault" @sonMethod="getInfo"></CustomerServiceControl>
    <!-- 套餐组件 -->
    <SetMealControl v-if="controlIndex == 19" :controlDefault="controlDefault" @sonMethod="getInfo"></SetMealControl>
    <!-- 卡项组件 -->
    <CardItemControl v-if="controlIndex == 20" :controlDefault="controlDefault" @sonMethod="getInfo"></CardItemControl>
    <!-- 服务组件 -->
    <ServiceGroupControl v-if="controlIndex == 21" :controlDefault="controlDefault" @sonMethod="getInfo"></ServiceGroupControl>
    <!-- 全部服务组件 -->
    <AllServiceControl v-if="controlIndex == 22" :controlDefault="controlDefault" @sonMethod="getInfo"></AllServiceControl>
    <!-- 商户详情组件 -->
    <ShopDetailControl v-if="controlIndex == 23" :controlDefault="controlDefault" @sonMethod="getInfo"></ShopDetailControl>
    <!-- 到店付款 -->
    <PayControl v-if="controlIndex == 24" :controlDefault="controlDefault" @sonMethod="getInfo"></PayControl>
    <!--数据滚动 -->
    <ScrollBarControl v-if="controlIndex == 25" :controlDefault="controlDefault" @sonMethod="getInfo"></ScrollBarControl>
    <!-- 素材库组件 -->
    <Style0Control v-if="controlIndex == 's_0'" :controlDefault="controlDefault" @sonMethod="getInfo"></Style0Control>
    <Style1Control v-if="controlIndex == 's_1'" :controlDefault="controlDefault" @sonMethod="getInfo"></Style1Control>
    <Style2Control v-if="controlIndex == 's_2'" :controlDefault="controlDefault" @sonMethod="getInfo"></Style2Control>
    <Style3Control v-if="controlIndex == 's_3'" :controlDefault="controlDefault" @sonMethod="getInfo"></Style3Control>
    <Style4Control v-if="controlIndex == 's_4'" :controlDefault="controlDefault" @sonMethod="getInfo"></Style4Control>
    <Style5Control v-if="controlIndex == 's_5'" :controlDefault="controlDefault" @sonMethod="getInfo"></Style5Control>
    <Style6Control v-if="controlIndex == 's_6'" :controlDefault="controlDefault" @sonMethod="getInfo"></Style6Control>
    <Style7Control v-if="controlIndex == 's_7'" :controlDefault="controlDefault" @sonMethod="getInfo"></Style7Control>
  </div>
</template>

<script>
import BannerControl from './BasicComponents/banner/bannerControl';
import SearchControl from './BasicComponents/search/searchControl';
import BtnGroupControl from './BasicComponents/btnGroup/btnGroupControl';
import PictureWindowControl from './BasicComponents/pictureWindow/pictureWindowControl';
import RichTextControl from './BasicComponents/richText/richTextControl';
import NoticeControl from './BasicComponents/notice/noticeControl';
import VideoControl from './BasicComponents/video/videoControl';
import GoodsGroupControl from './CommodityComponents/goodsGroup/goodsGroupControl';
import ShopGroupControl from './BasicComponents/shopGroup/shopGroupControl';
import TxtGroupControl from './BasicComponents/txtGroup/txtGroupControl';
import TitleBarControl from './BasicComponents/titleBar/titleBarControl';
import GuideControl from './BasicComponents/guide/guideControl';
import HeaderBgControl from './BasicComponents/headerBg/headerBgControl';
import AllGoodsControl from './CommodityComponents/allGoods/allGoodsControl';
import BasicSetting from './basicSetting/basicSettingControl';
import CouponControl from './MarketingComponent/Coupon/CouponControl';
import LiveRoom from './MarketingComponent/liveRoom/liveRoomControl';
import Collage from './MarketingComponent/collage/collageControl';
import VideoNumberControl from './MarketingComponent/videoNumber/videoNumberControl';
import SetMealControl from './MarketingComponent/setMeal/setMealControl';
import CustomerServiceControl from './BasicComponents/customerService/customerServiceControl';
import CardItemControl from './ServiceComponent/cardItem/cardItemControl.vue';
import ServiceGroupControl from './ServiceComponent/serviceGroup/serviceGroupControl.vue';
import AllServiceControl from './ServiceComponent/allService/allServiceControl.vue';
import ShopDetailControl from './ServiceComponent/shopDetail/shopDetailControl.vue';
import Style0Control from './MaterialLibrary/style_s_0/style_s_0_control.vue';
import Style1Control from './MaterialLibrary/style_s_1/style_s_1_control.vue';
import Style2Control from './MaterialLibrary/style_s_2/style_s_2_control.vue';
import Style3Control from './MaterialLibrary/style_s_3/style_s_3_control.vue';
import Style4Control from './MaterialLibrary/style_s_4/style_s_4_control.vue';
import Style5Control from './MaterialLibrary/style_s_5/style_s_5_control.vue';
import Style6Control from './MaterialLibrary/style_s_6/style_s_6_control.vue';
import Style7Control from './MaterialLibrary/style_s_7/style_s_7_control.vue';
import PayControl from './BasicComponents/pay/payControl';
import ScrollBarControl from './BasicComponents/scrollBar/scrollBarControl.vue';
export default {
  props: {
    controlDefault: {
      type: Array,
      default: () => {},
    },
    controlIndex: {
      type: Number,
      default: () => 0,
    },
    pageTitle: {
      type: String,
      default: '',
    },
    basicSet: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BannerControl,
    SearchControl,
    BtnGroupControl,
    PictureWindowControl,
    RichTextControl,
    NoticeControl,
    VideoControl,
    GoodsGroupControl,
    ShopGroupControl,
    TxtGroupControl,
    TitleBarControl,
    GuideControl,
    HeaderBgControl,
    AllGoodsControl,
    BasicSetting,
    CouponControl,
    LiveRoom,
    Collage,
    VideoNumberControl,
    CustomerServiceControl,
    SetMealControl,
    ShopDetailControl,
    CardItemControl,
    ServiceGroupControl,
    AllServiceControl,
    Style0Control,
    Style1Control,
    Style2Control,
    Style3Control,
    Style4Control,
    Style5Control,
    Style6Control,
    Style7Control,
    PayControl,
    ScrollBarControl,
  },
  methods: {
    getInfo(val, action, index) {
      this.$emit('getRes', val, action, index);
    },
    getBasicSet(val) {
      this.$emit('getSetInfo', val);
    },
  },
};
</script>

<style lang="less" scoped>
.BIgBOX {
  height: 100%;
}
</style>
