<template>
  <!-- :style="{ background: Guide.bgColor }" -->
  <div class="guideBox">
    <div class="contant" :style="{ height: Guide.height + 'px', background: Guide.color }"></div>
  </div>
</template>

<script>
export default {
  props: {
    Guide: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.guideBox {
  width: 100%;
  .contant {
    width: 100%;
  }
}
</style>
