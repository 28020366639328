<template>
  <div class="liveRoomBox" :style="{ backgroundColor: liveRoom.bgColor }">
    <div class="goodsLayout" v-if="liveRoom.layout === 0">
      <div class="top">
        <div class="liveStatusBox" :style="{ background: getStatusInfo.bgColor }">
          <img src="@/assets/image/liveRoom/live.png" alt="" />
          <div class="statusTxt">{{ getStatusInfo.text }}</div>
        </div>
        <div class="liveTitle" :style="{ color: liveRoom.fontColor }">{{ liveRoom.liveRoomInfo.title }}</div>
      </div>
      <div class="mid">
        <div v-for="(item, index) in liveRoom.liveRoomInfo.goods" :key="index" class="goodsItem">
          <div class="imgBox">
            <img :src="item.cover_img" alt="" />
            <div class="goodsPrice">￥{{ item.price }}</div>
          </div>
        </div>
      </div>
      <div class="bot">
        <div class="time"><img src="@/assets/image/liveRoom/time.png" alt="" />{{ getStartTime }} - {{ getEndTime }}</div>
        <div class="lookNum" :style="{ color: liveRoom.fontColor }">
          <img src="@/assets/image/liveRoom/eyes.png" alt="" />
          12258人{{ liveRoom.liveRoomInfo.live_status === 101 ? '在看' : liveRoom.liveRoomInfo.live_status === 102 ? '预约' : '观看' }}
        </div>
        <div class="lookBtn" :style="{ background: liveRoom.btnColor }">
          {{ liveRoom.liveRoomInfo.live_status === 101 ? '进入观看' : liveRoom.liveRoomInfo.live_status === 102 ? '预约观看' : '观看回放' }}
          <img src="@/assets/image/liveRoom/play.png" alt="" />
        </div>
      </div>
    </div>
    <div class="mainLayout" v-if="liveRoom.layout === 1">
      <div class="left">
        <img :src="liveRoom.liveRoomInfo.cover_img_url" alt="" />
        <div class="liveStatusBox" :style="{ background: getStatusInfo.bgColor }">
          <img src="@/assets/image/liveRoom/live.png" alt="" />
          <div class="statusTxt">{{ getStatusInfo.text }}</div>
        </div>
      </div>
      <div class="right">
        <div class="title" :style="{ color: liveRoom.fontColor }">{{ liveRoom.liveRoomInfo.title }}</div>
        <div class="time">直播时间:{{ getStartTime }} - {{ getEndTime }}</div>
        <div class="bot">
          <div class="left">
            <img :style="{ transform: index == 0 ? '' : 'translateX(-' + 8 * index + 'px)' }" v-for="(item, index) in 3" :key="index" src="@/assets/image/liveRoom/header.png" alt="" />
            <span :style="{ transform: ' scale(0.91) translateX(-12px)', whiteSpace: 'nowrap', color: liveRoom.fontColor }">
              12258人{{ liveRoom.liveRoomInfo.live_status === 101 ? '在看' : liveRoom.liveRoomInfo.live_status === 102 ? '预约' : '观看' }}
            </span>
          </div>
          <div class="lookBtn" :style="{ background: liveRoom.btnColor }">
            {{ liveRoom.liveRoomInfo.live_status === 101 ? '进入观看' : liveRoom.liveRoomInfo.live_status === 102 ? '预约观看' : '观看回放' }}
            <img src="@/assets/image/liveRoom/play.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getDate from '@/util/getDate.js'
export default {
  props: {
    liveRoom: {
      type: Object,
      default: null
    }
  },
  data() {
    return {}
  },
  computed: {
    getStatusInfo() {
      let result = null
      let status = this.liveRoom.liveRoomInfo.live_status
      if (status === 101) {
        result = { text: '直播中', bgColor: 'linear-gradient(180deg, #ff6066, #ff2435)' }
      } else if (status === 102) {
        result = { text: '未开始', bgColor: 'linear-gradient(180deg, #22C586, #17B88F)' }
      } else if (status === 103) {
        result = { text: '已结束', bgColor: 'linear-gradient(180deg, #D7D7DB, #BEBEC2)' }
      } else if (status === 104) {
        result = { text: '禁播', bgColor: 'linear-gradient(180deg, #D7D7DB, #BEBEC2)' }
      } else if (status === 105) {
        result = { text: '暂停', bgColor: 'linear-gradient(180deg, #D7D7DB, #BEBEC2)' }
      } else if (status === 106) {
        result = { text: '异常', bgColor: 'linear-gradient(180deg, #D7D7DB, #BEBEC2)' }
      } else if (status === 107) {
        result = { text: '已过期', bgColor: 'linear-gradient(180deg, #D7D7DB, #BEBEC2)' }
      }
      return result
    },
    getStartTime() {
      let res = getDate.getDate(this.liveRoom.liveRoomInfo.starttime * 1000)
      return `${res.month}-${res.day} ${res.hour}:${res.minutes}`
    },
    getEndTime() {
      let res = getDate.getDate(this.liveRoom.liveRoomInfo.endtime * 1000)
      return `${res.month}-${res.day} ${res.hour}:${res.minutes}`
    }
  }
}
</script>

<style lang="less" scoped>
.liveRoomBox {
  width: 100%;
  padding: 3px 13px;
  .goodsLayout {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 14px 12px;
    border-radius: 8px;
    .top {
      display: flex;
      align-items: center;
      .liveStatusBox {
        width: 52px;
        height: 18px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 0 7px;
        margin-right: 4px;
        img {
          width: 12px !important;
          height: 12px !important;
        }
        .statusTxt {
          font-size: 12px;
          transform: scale(0.75) translateX(-3px);
          color: #fff;
          white-space: nowrap;
        }
      }
      .liveTitle {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .mid {
      margin: 14px 0;
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
      display: flex;
      align-items: center;
      min-height: 84px;
      .goodsItem {
        position: relative;
        &:not(:first-child) {
          margin-left: 11px;
        }
        .imgBox {
          img {
            width: 84px !important;
            height: 84px !important;
            border-radius: 4px;
          }
          .goodsPrice {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 84px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            font-size: 12px;
            color: #fff;
            background: linear-gradient(0deg, rgba(102, 102, 102, 0.6) 0%, rgba(102, 102, 102, 0.04) 100%);
            border-radius: 0px 0px 8px 8px;
          }
        }
      }
    }
    .bot {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;
      .time {
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: #999999;
        img {
          width: 12px !important;
          height: 12px !important;
          vertical-align: middle;
        }
      }
      .lookNum {
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: #999999;
        margin: 0 7px 0;
        img {
          width: 12px !important;
          height: 12px !important;
          vertical-align: middle;
        }
      }
      .lookBtn {
        width: 83px;
        height: 28px;
        border-radius: 4px;
        line-height: 28px;
        font-size: 12px;
        color: #fff;
        text-align: center;
        img {
          width: 12px !important;
          height: 12px !important;
          vertical-align: middle;
        }
      }
    }
  }
  .mainLayout {
    width: 100%;
    display: flex;
    .left {
      position: relative;
      margin-right: 12px;
      img {
        width: 120px !important;
        height: 120px !important;
        border-radius: 4px;
      }
      .liveStatusBox {
        width: 52px;
        height: 18px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 0 7px;
        margin-right: 4px;
        position: absolute;
        top: 0;
        left: 0;
        img {
          width: 12px !important;
          height: 12px !important;
        }
        .statusTxt {
          font-size: 12px;
          transform: scale(0.75) translateX(-3px);
          color: #fff;
          white-space: nowrap;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      flex: 1;
      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .time {
        flex: 1;
        font-size: 12px;
        color: #999999;
        margin-top: 13px;
        transform: scale(0.91) translateX(-10px);
      }
      .bot {
        height: 26px;
        display: flex;
        align-items: center;

        .left {
          height: 100%;
          display: flex;
          align-items: center;
          img {
            width: 18px !important;
            height: 18px !important;
            vertical-align: middle;
          }
        }
        .lookBtn {
          width: 83px;
          height: 28px;
          border-radius: 4px;
          line-height: 28px;
          font-size: 12px;
          color: #fff;
          text-align: center;
          img {
            width: 12px !important;
            height: 12px !important;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>
