<template>
  <div class="coupon" :style="{ background: Coupon.bgColor }">
    <div>
      <!-- 样式一 数量一 -->
      <div v-if="Coupon.layout == 0 && Coupon.rowCount == 1" class="coupon0-1">
        <i class="iconfont icon-youhuiquanzujian_huaban1" :style="{ color: Coupon.componentColor }"></i>
        <div class="coupon0-1-txt" v-for="(item, index) in Coupon.list" :key="index">
          <div :style="{ fontWeight: 'bold', fontSize: '20px' }">
            <span :style="{ fontSize: '16px' }" v-if="item.type == 1 || item.type == 2">￥</span>
            {{ item.type == 1 ? item.subtract : item.type == 2 ? item.subtract : item.discount }}
            <span :style="{ fontSize: '16px' }" v-if="item.type == 3">折</span>
          </div>
          <div :style="{ fontSize: '12px' }">{{ item.type == 1 ? '满' + item.consume_full + '可用' : item.type == 2 ? '无门槛立减' : '满' + item.consume_full + '可用' }}</div>
          <div class="btn" :style="{ color: Coupon.componentColor }">立即领取</div>
        </div>
      </div>
      <!-- 样式一 数量二 -->
      <div v-if="Coupon.layout == 0 && Coupon.rowCount == 2" class="coupon0-2">
        <i class="iconfont icon-youhuiquanzujian-02" :style="{ color: Coupon.componentColor }"></i>
        <div class="coupon0-2-txt">
          <div v-for="(item, index) in Coupon.list" :key="index">
            <div :style="{ fontWeight: 'bold', fontSize: '20px', whiteSpace: ' nowrap' }">
              <span :style="{ fontSize: '16px' }" v-if="item.type == 1 || item.type == 2">￥</span>
              {{ item.type == 1 ? item.subtract : item.type == 2 ? item.subtract : item.discount }}
              <span :style="{ fontSize: '16px' }" v-if="item.type == 3">折</span>
            </div>
            <div>
              <div :style="{ fontSize: '12px', whiteSpace: ' nowrap' }">
                {{ item.type == 1 ? '满' + item.consume_full + '可用' : item.type == 2 ? '无门槛立减' : '满' + item.consume_full + '可用' }}
              </div>
              <div class="btn" :style="{ color: Coupon.componentColor }">立即领取</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 样式一 数量三 -->
      <div v-if="Coupon.layout == 0 && Coupon.rowCount == 3" class="coupon0-3">
        <i class="iconfont icon-youhuiquanzujian-03" :style="{ color: Coupon.componentColor }"></i>
        <div class="coupon0-3-txt">
          <div v-for="(item, index) in Coupon.list" :key="index">
            <div :style="{ fontWeight: 'bold', fontSize: '20px', whiteSpace: ' nowrap' }">
              <span :style="{ fontSize: '16px' }" v-if="item.type == 1 || item.type == 2">￥</span>
              {{ item.type == 1 ? item.subtract : item.type == 2 ? item.subtract : item.discount }}
              <span :style="{ fontSize: '16px' }" v-if="item.type == 3">折</span>
            </div>
            <div :style="{ fontSize: '12px', whiteSpace: ' nowrap', margin: '7px 0' }">
              {{ item.type == 1 ? '满' + item.consume_full + '可用' : item.type == 2 ? '无门槛立减' : '满' + item.consume_full + '可用' }}
            </div>
            <div class="btn" :style="{ color: Coupon.componentColor }">立即领取</div>
          </div>
        </div>
      </div>
      <!-- 样式二 数量一 -->
      <div v-if="Coupon.layout == 1 && Coupon.rowCount == 1" class="coupon1-1">
        <i class="iconfont icon-youhuiquanzujian-04" :style="{ color: Coupon.componentColor }"></i>
        <div class="coupon1-1-txt" :style="{ color: Coupon.componentColor }" v-for="(item, index) in Coupon.list" :key="index">
          <div :style="{ fontWeight: 'bold', fontSize: '20px' }">
            <span :style="{ fontSize: '16px' }" v-if="item.type == 1 || item.type == 2">￥</span>
            {{ item.type == 1 ? item.subtract : item.type == 2 ? item.subtract : item.discount }}
            <span :style="{ fontSize: '16px' }" v-if="item.type == 3">折</span>
          </div>
          <div :style="{ fontSize: '12px' }">{{ item.type == 1 ? '满' + item.consume_full + '可用' : item.type == 2 ? '无门槛立减' : '满' + item.consume_full + '可用' }}</div>
          <div class="btn">立即领取</div>
        </div>
      </div>
      <!-- 样式二 数量二 -->
      <div v-if="Coupon.layout == 1 && Coupon.rowCount == 2" class="coupon1-2">
        <div v-for="(item, index) in Coupon.list" :key="index">
          <i class="iconfont icon-youhuiquanzujian-05" :style="{ color: Coupon.componentColor }"> </i>
          <div class="coupon1-2-txt">
            <div :style="{ color: Coupon.componentColor }">
              <div :style="{ fontWeight: 'bold', fontSize: '20px' }">
                <span :style="{ fontSize: '16px' }" v-if="item.type == 1 || item.type == 2">￥</span>{{ item.type == 1 ? item.subtract : item.type == 2 ? item.subtract : item.discount
                }}<span :style="{ fontSize: '16px' }" v-if="item.type == 3">折</span>
              </div>
              <div :style="{ fontSize: '12px' }">{{ item.type == 1 ? '满' + item.consume_full + '可用' : item.type == 2 ? '无门槛立减' : '满' + item.consume_full + '可用' }}</div>
            </div>
            <div class="btn">立即领取</div>
          </div>
        </div>
      </div>
      <!-- 样式二 数量二 -->
      <div v-if="Coupon.layout == 1 && Coupon.rowCount == 3" class="coupon1-2 coupon1-3">
        <div v-for="(item, index) in Coupon.list" :key="index">
          <i class="iconfont icon-youhuiquanzujian-06" :style="{ color: Coupon.componentColor }"></i>
          <div class="coupon1-3-txt" :style="{ color: Coupon.componentColor }">
            <div :style="{ fontWeight: 'bold', fontSize: '20px' }">
              <span :style="{ fontSize: '16px' }" v-if="item.type == 1 || item.type == 2">￥</span>{{ item.type == 1 ? item.subtract : item.type == 2 ? item.subtract : item.discount
              }}<span :style="{ fontSize: '16px' }" v-if="item.type == 3">折</span>
            </div>
            <div :style="{ fontSize: '12px', marginTop: '-10px' }">{{ item.type == 1 ? '满' + item.consume_full + '可用' : item.type == 2 ? '无门槛立减' : '满' + item.consume_full + '可用' }}</div>
            <div class="btn">立即领取</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    Coupon: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.coupon {
  width: 100%;
  padding: 0 0 3px 0;
  & > div {
    height: 100%;
    margin: 0 13px;
    .coupon0-1 {
      text-align: center;
      position: relative;
      .coupon0-1-txt {
        width: 100%;
        padding: 0 32px 0 26px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        .btn {
          width: 60px;
          height: 24px;
          line-height: 24px;
          border-radius: 12px;
        }
      }
    }
    .coupon0-2 {
      color: #fff;
      position: relative;
      text-align: center;
      .coupon0-2-txt {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;

        & > div {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 8px;
          & > div:first-child {
            margin: 0 5px;
          }
          & > div:last-child {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 7px 0;
          }
          .btn {
            width: 60px;
            height: 16px;
            line-height: 16px;
            border-radius: 8px;
          }
        }
      }
    }
    .coupon0-3 {
      width: 100%;
      position: relative;
      text-align: center;
      color: #fff;
      i {
        font-size: 80px;
      }
      .coupon0-3-txt {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        & > div {
          flex: 0 0 106px;
          flex-shrink: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        & > div:first-child {
          flex: 0 0 113px;
        }
        & > div:last-child {
          flex: 0 0 124px;
        }
        .btn {
          width: 60px;
          height: 16px;
          line-height: 16px;
          border-radius: 8px;
          margin: 0 auto;
        }
      }
    }
    .coupon1-1 {
      width: 250px;
      height: 52px;
      text-align: center;
      background-color: #fff;
      position: relative;
      vertical-align: top;
      top: -4px;
      border-radius: 12px;
      .coupon1-1-txt {
        width: 343px;
        height: 52px;
        position: absolute;
        top: 4px;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 0 20px 0 30px;
        .btn {
          width: 60px;
          height: 24px;
          line-height: 24px;
          font-weight: bold;
          border-radius: 12px;
        }
      }
    }
    .coupon1-2 {
      width: 100%;
      height: 81px;
      display: flex;
      overflow: hidden;
      justify-content: space-between;
      & > div {
        width: 167px;
        height: 81px;
        position: relative;
        top: -6px;
        background-color: #fff;
        border-radius: 19px;
      }
      i {
        font-size: 81px;
      }
      .coupon1-2-txt {
        width: 100%;
        height: 81px;
        position: absolute;
        top: 6px;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0 6px 0;
        & > div:first-child {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
        }
        .btn {
          background-color: transparent;
          color: #fff;
          font-weight: bold;
        }
      }
    }
    .coupon1-3 {
      & > div {
        width: 96px;
        height: 90px;
        .coupon1-3-txt {
          width: 95px;
          height: 90px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: absolute;
          top: 0;
          padding: 15px 0 7px 0;
          .btn {
            background-color: transparent;
            color: #fff;
          }
        }
      }
    }
  }
  i {
    font-size: 52px;
  }
  .btn {
    text-align: center;
    background: #ffffff;
    font-size: 12px;
  }
}
</style>
